import React from 'react';
import { NavLink } from 'react-router-dom';
import { urls, mail } from '../config';
import { useUser } from '../hooks/useUser';

function openMenu() {
    const htmlMain = document.querySelector('html');
    const hamburger = document.querySelector('.hamburger');
    const navigation = document.querySelector('.navigation');
    const navbar = document.querySelector('.navbar');
    const navbarText = document.querySelector('.navbar-text');

    htmlMain.classList.toggle('overflow-hidden');
    hamburger.classList.toggle('active');
    navigation.classList.toggle('active');
    navbar.classList.toggle('active');
    navbarText.classList.toggle('active');
}

const thisYear = new Date().getFullYear();

function Navbar() {

    const userInfo = useUser();

    return (
        <>
            <NavLink to="/pricing" activeclassname="active" className="header-info-bar">
                <div className="header-info-bar-text">
                    <span>🚀</span> <span>{"Let's grow together, check our early bird prices"}</span>
                </div>
            </NavLink>
            <header className="navbar">
                <div className="navbar-container">
                    <div className="logo">
                        <a href="/"><img src="../imgs/Diktatatorial.svg" alt="Diktatorial" /></a>
                    </div>
                    <nav className="navigation">
                        <NavLink to="/" activeclassname="active" className="nav-link" onClick={openMenu}>Home</NavLink>
                        <NavLink to="/pricing" activeclassname="active" className="nav-link" onClick={openMenu}>Pricing</NavLink>
                        <NavLink to="/faq" activeclassname="active" className="nav-link" onClick={openMenu}>FAQ</NavLink>
                        <NavLink to="/about" activeclassname="active" className="nav-link" onClick={openMenu}>About</NavLink>
                        {
                            userInfo ?
                            <a href={urls.login} className="nav-link only-mobile mt-5" target="_blank" rel="noreferrer" onClick={openMenu}>Dashboard</a>
                            :
                            <>
                                <a href={urls.login} className="nav-link only-mobile mt-5" target="_blank" rel="noreferrer" onClick={openMenu}>Sign In</a>
                                <a href={urls.register} className="nav-link only-mobile" target="_blank" rel="noreferrer" onClick={openMenu}>Register</a>
                            </>
                        }
                        <a href={`mailto:${mail.email}?subject=${encodeURIComponent(mail.subject)}&body=${encodeURIComponent(mail.body)}`} className="nav-link only-mobile" onClick={openMenu}>Contact</a>

                        <a href={userInfo ? urls.home : urls.register} className="open-suite" target="_blank" rel="noreferrer" onClick={openMenu}>{userInfo ? "Dashboard" : "Try Beta"}</a>

                        <div className="socials only-mobile">
                            <a href={urls.socialMedia.twitter} target="_blank" rel="noopener noreferrer" className="footer-link">
                                <div className="footer-icons_4241100" id="id-I8747577_4241100">
                                    <div
                                        className="footer-icons_4241100_119"
                                        id="id-I8747577_4241100_119"
                                    >
                                        <div
                                            className="footer-bg_4241100_119"
                                            id="id-bg-I8747577_4241100_119"
                                        ></div>
                                    </div>
                                </div>
                            </a>
                            <a href={urls.socialMedia.instagram} target="_blank" rel="noopener noreferrer" className="footer-link">
                                <div className="footer-icons_4241101" id="id-I8747577_4241101">
                                    <div
                                        className="footer-icons_4241101_121"
                                        id="id-I8747577_4241101_121"
                                    >
                                        <div
                                            className="footer-icons_4241101_122"
                                            id="id-I8747577_4241101_122"
                                        >
                                            <div
                                                className="footer-bg_4241101_122"
                                                id="id-bg-I8747577_4241101_122"
                                            ></div>
                                        </div>
                                        <div
                                            className="footer-icons_4241101_123"
                                            id="id-I8747577_4241101_123"
                                        >
                                            <div
                                                className="footer-bg_4241101_123"
                                                id="id-bg-I8747577_4241101_123"
                                            ></div>
                                        </div>
                                        <div
                                            className="footer-icons_4241101_124"
                                            id="id-I8747577_4241101_124"
                                        >
                                            <div
                                                className="footer-bg_4241101_124"
                                                id="id-bg-I8747577_4241101_124"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a href={urls.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" className="footer-link">
                                <div className="footer-icons_4241102" id="id-I8747577_4241102">
                                    <div
                                        className="footer-icons_4241102_126"
                                        id="id-I8747577_4241102_126"
                                    >
                                        <div
                                            className="footer-icons_4241102_127"
                                            id="id-I8747577_4241102_127"
                                        >
                                            <div
                                                className="footer-bg_4241102_127"
                                                id="id-bg-I8747577_4241102_127"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <NavLink to="/terms-of-use" activeclassname="active" className="nav-link nav-link-alternate only-mobile mt-5" onClick={openMenu}>TERMS OF USE</NavLink>
                        <NavLink to="/privacy-policy" activeclassname="active" className="nav-link nav-link-alternate only-mobile" onClick={openMenu}>PRIVACY POLICY</NavLink>
                        <div className="navbar-text">
                            <div className="navbar-text-container">Made by musicians.</div>
                            <div className="navbar-text-container">©️ {thisYear} DIKTATORIAL Inc. All rights reserved.</div>
                        </div>
                    </nav>
                    <div className="hamburger" onClick={openMenu}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;
