import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ReleaseNotes() {

    useEffect(() => {
        document.title = "Product Updates | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="parent-div faq-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            <div className="regular-container">

                <div className="titling">
                    <div className="titling-0">Diktatorial Suite</div>
                    <div className="titling-1">Product Updates</div>
                </div>

                <div className="regular-wrapper">
                <h3>April 14, 2024</h3>
                    <p>
                        We’ve upgraded our mastering algorithm. Audio quality has been improved and all mastering outputs should render at higher quality now. Cheers!
                    </p>

                    <br />      
                    <h3>April 9, 2024</h3>
                    <p>
                    Here’s our first blog entry: <a href='https://diktatorial.com/blog/discover-ilk-zamanlar-an-interview-on-their-sound-and-mastering' target='_self' rel='noopener noreferrer'><b>Discover İlk Zamanlar: An Interview on Their Sound and Mastering</b></a>
                    </p>

                    <br />      
                    <h3>March 26, 2024</h3>
                    <p>
                     <a href='https://www.producthunt.com/posts/diktatorial-suite' target='_blank' rel='noopener noreferrer'>We won the Product Hunt and we are listed as <b>Product of the Day #4! 🥳</b></a>
                    </p>

                    <br />                      
                <h3>March 18, 2024</h3>
                    <p>
                     Limited time - launch offer: Lifetime Suite access plans are available now. <a href='https://diktatorial.com/pricing' target='_self' rel='noopener noreferrer'><b>Check here.</b></a>
                    </p>

                    <br />  
                <h3>March 13, 2024</h3>
                    <p>
                        Just one month post-launch, and we have already hit the remarkable milestone of 1000 masterings! Cheers to this incredible achievement, thank you! 🎉
                    </p>

                    <br />                    

                    <h3>March 2, 2024</h3>
                    <p>
                        Prompt to mastering was not respecting to loudness related prompts time to time. We’ve fixed it and <a href='https://www.youtube.com/watch?v=4xgx4k83zzc' target='_blank' rel='noopener noreferrer'><b>it goes to 11 now.</b></a> 
                    </p>

                    <br />

                    <h3>February 27, 2024</h3>
                    <p>
                        Our precious mobile version of Suite just went live! <a href='https://suite.diktatorial.com' target='_blank' rel='noopener noreferrer'><b>Visit Suite</b></a> on your mobile to check it out!
                    </p>

                    <br />                    

                    <h3>February 23, 2024</h3>
                    <p>
                        We’ve a Youtube channel now! <a href='https://www.youtube.com/@Diktatorial' target='_blank' rel='noopener noreferrer'><b>Subscribe @Diktatorial</b></a>
                    </p>

                    <br />

                    <h3>February 20, 2024</h3>
                    <p>
                        “True Peak” option has been added to Revision Suite. “Prompt to Mastering” will also respect your related prompts to TP.
                    </p>

                    <br />

                    <h3>February 12, 2024</h3>
                    <p>
                        Early bird sale is on. Check Pricing page.
                    </p>

                    <br />

                    <h3>February 8, 2024</h3>
                    <p>
                        We’ve started! Diktatorial Suite is released as BETA version with its revolutionary online mastering tool for musicians worldwide, combining deep learning with LLM technology.
                    </p>

                </div>

            </div>

        </div>
    );
}

export default ReleaseNotes;
