// hooks/utils.js
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';

export const useUser = () => {
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const cookieKeys = ['_d_i', '_d_e', '_d_a', '_d_r'];
        const yokboylebisey = "inanilmaz_gizli_bir_anahtar";

        cookieKeys.forEach(key => {
            const encryptedValue = decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;\\s*)${key}\\s*\\=\\s*([^;]*).*$)|^.*$`), "$1"));
            if (!encryptedValue) return;

            try {
                const bytes = CryptoJS.AES.decrypt(encryptedValue, yokboylebisey);
                const decryptedValue = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                setUserInfo(prev => ({ ...prev, [key]: decryptedValue }));
            } catch (error) {
                console.error('Decrypt error:', error);
            }
        });
    }, []);

    return userInfo;
};
