const BASE_URL = "https://suite.diktatorial.com";

export const urls = {
    home: BASE_URL,
    login: `${BASE_URL}/login`,
    register: `${BASE_URL}/register`,
    subscribe: `${BASE_URL}/credits-subscriptions`,
    socialMedia: {
        twitter: "https://twitter.com/diktatorial_ai",
        instagram: "https://www.instagram.com/diktatorial.suite/",
        linkedin: "https://www.linkedin.com/company/diktatorial-suite/",
    },
};

export const mail = {
    email: "info@diktatorial.com",
    subject: "Hello Diktatorial",
    body: "Hi, my name is ....",
}

export const user = {
    berkan: {
        instagram: "https://www.instagram.com/berkancesur/",
        linkedin: "https://www.linkedin.com/in/berkancesur/",
        spotify: "https://open.spotify.com/intl-tr/artist/5dUOfxMZOkCnJVwhbZm5ta?si=rYR_C7dPSd6mpYezWrBNVw",
        github: "https://github.com/Slavezax",
        twitter: "https://twitter.com/berkan_cesur"
    },
    selin: {
        instagram: "https://www.instagram.com/cestlinn/",
        linkedin: "https://www.linkedin.com/in/selintunr/",
        spotify: "https://open.spotify.com/artist/0sny6JY03dmZtZRJ31rPb8?si=YCEYIc7OQjyHG4HCO3l0Lg",
        github: "https://github.com/selintunr",
        twitter: "#"
    },
    tolga: {
        instagram: "https://www.instagram.com/themurdark/",
        linkedin: "https://www.linkedin.com/in/tolgaaraboglu",
        spotify: "https://open.spotify.com/artist/4qdXlTgqUJ1ia7el6J4St9?si=u2xs9GZxQ-OeG7Di2hf9lw",
        github: "https://github.com/tolgaaraboglu",
        twitter: "#"
    },
    can: {
        instagram: "https://www.instagram.com/yeraltipilotu/",
        linkedin: "https://www.linkedin.com/in/can-ferman/",
        spotify: "#",
        github: "https://github.com/canferman",
        twitter: "#"
    }
}