
import axios from 'axios'

export const apiInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: "https://api.diktatorial.com",
});

apiInstance.interceptors.request.use((config) => {
    if (!config.url.endsWith('/')) {
        if (!config.url.includes('verify-email')) {
            config.url += '/';
        }
    }
    return config;
});
