import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function TermsOfUse() {

    useEffect(() => {
        document.title = "Terms of Use | DIKTATORIAL Suite";
    }, []);
    

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="parent-div faq-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            <div className="regular-container">

                <div className="titling">
                    <div className="titling-0">Diktatorial Suite</div>
                    <div className="titling-1">Terms of Use</div>
                </div>

                <div className="regular-wrapper">

                    <h2>Welcome & Acceptance</h2>
                    <p>
                        Thank you for choosing DIKTATORIAL SUITE (&quot;Service&quot;). We are
                        proud to be a product of DIKTATORIAL Inc. (&quot;DIKTATORIAL&quot;). The
                        following Terms of Use (&quot;Terms&quot;) outline the rules and guidelines for
                        using our Service. Please read them carefully. By accessing or
                        using the Service, you acknowledge your understanding and
                        consent to these Terms.
                    </p>

<hr />
                    <h3>1. Access and License</h3>
                    <p>
                        DIKTATORIAL bestows upon you a non-exclusive, non-transferable,
                        and limited privilege to access and utilize the Service, strictly
                        adhering to these Terms.
                    </p>

                    <p>
                        Our terms are simple. We don’t own your tracks. You simply can
                        monetize any output you can get from Diktatorial Suite.
                    </p>

<br />
                    <h3>2. Conduct and Restrictions</h3>
                    <p>
                        While using our Service, please refrain from:
                    </p>

                    <p>
                        a. Sharing or promoting content that may be deemed unlawful,
                        harmful, threatening, abusive, offensive, or invasive of someone
                        else&apos;s privacy
                    </p>

                    <p>
                        b. Posting any material that might infringe upon the intellectual
                        property rights of DIKTATORIAL or other third parties.
                    </p>

                    <p>
                        c. Pretending to be someone else, or providing misleading
                        information about your affiliation.
                    </p>

                    <p>
                        d. Disturbing the Service&apos;s seamless operation or the systems
                        supporting it.
                    </p>

                    <p>
                        e. Employing automated tools, like robots or scrapers, without our
                        permission
                    </p>

                    <p>
                        f. Storing or gathering personal details of other users without explicit
                        permission.
                    </p>

                    <br />
                    <h3>3. Your Contributions</h3>
                    <p>
                        Firstly, we do not own your artistic work and you can distribute your mastered files commercially. 
                        While any content (&quot;User Content&quot;) you contribute remains yours,
                        sharing it gives DIKTATORIAL a non-exclusive, royalty-free, global
                        permission to adapt, showcase (with your permission again), and
                        associate said content with our Service and our brand. Rest
                        assured, we respect your rights and will not distribute your musical
                        or audio pieces to third parties unless you direct us to. For our
                        subscribers, we offer cloud storage. However, should your
                        subscription lapse, we reserve the right to remove your files.
                        Subscription plans are available both monthly and annually, with
                        terms subject to change. Producers and studio owners must
                        possess rightful ownership to share tracks on DIKTATORIAL. 
                    </p>

                    <br />
                    <h3>4. Moderation Rights</h3>
                    <p>
                        DIKTATORIAL holds the discretion to remove any User Content
                        without prior notice, ensuring the best experience for all.
                    </p>

                    <br />
                    <h3>5. Account Termination</h3>
                    <p>
                        We reserve the right to conclude your access to our Service for any
                        reason, ensuring our community&apos;s integrity.
                    </p>

                    <br />
                    <h3>6. Applicable Law</h3>
                    <p>
                        Your association with us and these Terms fall under the jurisdiction
                        of the State of Delaware, irrespective of conflicting legal principles.
                    </p>

                    <br />
                    <h3>7. Full Agreement</h3>
                    <p>
                        These Terms represent the comprehensive agreement concerning
                        your engagement with the Service.
                    </p>

                    <br />
                    <h3>8. Clause Validity</h3>
                    <p>
                        Should any clause here be deemed inapplicable, the rest of the
                        Terms remain in effect.
                    </p>

                    <br />
                    <h3>9. Relinquishment of Rights</h3>
                    <p>
                        Any waiver related to these Terms requires a written agreement
                        from both parties.
                    </p>

                    <br />
                    <hr />
                    <br />
                    <p>
                        Thank you for being part of the DIKTATORIAL family. We are
                        committed to offering a top-notch experience. If you have any
                        concerns or queries, please reach out to us.
                    </p>

                </div>

            </div>

        </div>
    );
}

export default TermsOfUse;
