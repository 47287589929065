import React from 'react';
import { Link } from 'react-router-dom';
import { urls, mail } from '../config';
import { useUser } from '../hooks/useUser';

const thisYear = new Date().getFullYear();

function Footer() {

    const userInfo = useUser();

    return (
        <footer>
            <div className="footer-section" id="id-8747577">

                <div className="footer-container" id="id-I8747577_424942">

                    <div className="footer-icon-bg" id="id-I8747577_424943"></div>

                    <div className="footer-left">
                        <div className="footer-left-heading" id="id-I8747577_4241111">
                            <span className="footer-left-heading-0"
                            >Try the best AI mastering software in the world.</span
                            >
                        </div>
                        <div className="footer-left-deesc" id="id-I8747577_4241112">
                            <span className="footer-left-deesc-0"
                            >Your virtual sound engineer and AI mastering tool with text prompts. Musical, instant and creative.<br /><br /> It upscales audio and gives the comfort of hiring an expensive mastering engineer. Try free.</span
                            >
                        </div>
                        <div className="footer-left-bottom" id="id-I8747577_4241114">
                            <span className="footer-left-bottom-0"
                            >Proudly developed by nerdy musicians.<br />©️ {thisYear} DIKTATORIAL Inc. All rights
                                reserved.</span
                            >
                        </div>
                    </div>

                    <div className="footer-right">

                        <div className="footer-right-top">

                            <div className="footer-right-left">
                                <div className="footer-right-left-heading" id="id-I8747577_4241108">
                                    <span className="footer-right-left-heading-0">Visit</span>
                                </div>
                                <div className="footer-right-links" id="id-I8747577_4241104">
                                    <span className="footer-right-links-0">
                                        <Link to="/" className="footer-link">Home</Link>
                                    </span>
                                </div>
                                <div className="footer-right-links" id="id-I8747577_4241105">
                                    <span className="footer-right-links-0">
                                        <Link to="/pricing" className="footer-link">Pricing</Link>
                                    </span>
                                </div>
                                <div className="footer-right-links" id="id-I8747577_4241106">
                                    <span className="footer-right-links-0">
                                        <Link to="/faq" className="footer-link">FAQ</Link>
                                    </span>
                                </div>
                                <div className="footer-right-links" id="id-I8747577_4241107">
                                    <span className="footer-right-links-0">
                                        <Link to="/about" className="footer-link">About</Link>
                                    </span>
                                </div>
                                <div className="footer-right-icons" id="id-I8747577_4241099">
                                    <a href={urls.socialMedia.twitter} target="_blank" rel="noopener noreferrer" className="footer-link">
                                        <div className="footer-icons_4241100" id="id-I8747577_4241100">
                                            <div
                                                className="footer-icons_4241100_119"
                                                id="id-I8747577_4241100_119"
                                            >
                                                <div
                                                    className="footer-bg_4241100_119"
                                                    id="id-bg-I8747577_4241100_119"
                                                ></div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={urls.socialMedia.instagram} target="_blank" rel="noopener noreferrer" className="footer-link">
                                        <div className="footer-icons_4241101" id="id-I8747577_4241101">
                                            <div
                                                className="footer-icons_4241101_121"
                                                id="id-I8747577_4241101_121"
                                            >
                                                <div
                                                    className="footer-icons_4241101_122"
                                                    id="id-I8747577_4241101_122"
                                                >
                                                    <div
                                                        className="footer-bg_4241101_122"
                                                        id="id-bg-I8747577_4241101_122"
                                                    ></div>
                                                </div>
                                                <div
                                                    className="footer-icons_4241101_123"
                                                    id="id-I8747577_4241101_123"
                                                >
                                                    <div
                                                        className="footer-bg_4241101_123"
                                                        id="id-bg-I8747577_4241101_123"
                                                    ></div>
                                                </div>
                                                <div
                                                    className="footer-icons_4241101_124"
                                                    id="id-I8747577_4241101_124"
                                                >
                                                    <div
                                                        className="footer-bg_4241101_124"
                                                        id="id-bg-I8747577_4241101_124"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a href={urls.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" className="footer-link">
                                        <div className="footer-icons_4241102" id="id-I8747577_4241102">
                                            <div
                                                className="footer-icons_4241102_126"
                                                id="id-I8747577_4241102_126"
                                            >
                                                <div
                                                    className="footer-icons_4241102_127"
                                                    id="id-I8747577_4241102_127"
                                                >
                                                    <div
                                                        className="footer-bg_4241102_127"
                                                        id="id-bg-I8747577_4241102_127"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className="footer-right-right">

                                <div className="footer-right-right-heading"></div>

                                {
                                    userInfo ?
                                        <div className="footer-right-links" id="id-I8747577_4241096">
                                            <span className="footer-right-links-0">
                                                <a href={urls.login} target="_blank" rel="noreferrer" className="footer-link">Dashboard</a>
                                            </span>
                                        </div>
                                    :
                                    <>
                                        <div className="footer-right-links" id="id-I8747577_4241096">
                                            <span className="footer-right-links-0">
                                                <a href={urls.login} target="_blank" rel="noreferrer" className="footer-link">Sign In</a>
                                            </span>
                                        </div>

                                        <div className="footer-right-links" id="id-I8747577_4241097">
                                            <span className="footer-right-links-0">
                                                <a href={urls.register} target="_blank" rel="noreferrer" className="footer-link">Register</a>
                                            </span>
                                        </div>
                                    </>
                                }


                                <div className="footer-right-links" id="id-I8747577_4241098">
                                    <span className="footer-right-links-0">
                                        <a href={`mailto:${mail.email}?subject=${encodeURIComponent(mail.subject)}&body=${encodeURIComponent(mail.body)}`} id="footer-contact" className="footer-link">Contact</a>
                                    </span>
                                </div>

                                <div className="footer-right-links" id="id-I8747577_4241099">
                                    <span className="footer-right-links-0">
                                        <a href="/release-notes" className="footer-link">Product Updates</a>
                                    </span>
                                </div>

                            </div>

                        </div>

                        <div className="footer-right-bottom">
                            <div className="footer-right-extras" id="id-I8747577_4241109">
                                <span className="footer-right-extras-0">
                                    <a href="/terms-of-use" className="footer-link">TERMS OF USE</a>
                                </span>
                            </div>
                            <div className="footer-right-extras" id="id-I8747577_4241110">
                                <span className="footer-right-extras-0">
                                    <a href="/privacy-policy" className="footer-link">PRIVACY POLICY</a>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div className="footer-middle" id="id-I8747577_424944">
                        <div className="footer-middle-bg" id="id-I8747577_424945"></div>

                        <div className="footer-middle-icon" id="id-I8747577_4241113">
                            <div
                                className="footer-middle-icon_325459"
                                id="id-I8747577_4241113_325459"
                            >
                                <div
                                    className="footer-bg_4241113_325459"
                                    id="id-bg-I8747577_4241113_325459"
                                ></div>
                            </div>
                            <div
                                className="footer-middle-icon_325453"
                                id="id-I8747577_4241113_325453"
                            >
                                <div
                                    className="footer-middle-icon_325454"
                                    id="id-I8747577_4241113_325454"
                                >
                                    <div
                                        className="footer-bg_4241113_325454"
                                        id="id-bg-I8747577_4241113_325454"
                                    ></div>
                                </div>
                                <div
                                    className="footer-middle-icon_325455"
                                    id="id-I8747577_4241113_325455"
                                >
                                    <div
                                        className="footer-bg_4241113_325455"
                                        id="id-bg-I8747577_4241113_325455"
                                    ></div>
                                </div>
                                <div
                                    className="footer-middle-icon_325456"
                                    id="id-I8747577_4241113_325456"
                                >
                                    <div
                                        className="footer-bg_4241113_325456"
                                        id="id-bg-I8747577_4241113_325456"
                                    ></div>
                                </div>
                                <div
                                    className="footer-middle-icon_325457"
                                    id="id-I8747577_4241113_325457"
                                >
                                    <div
                                        className="footer-bg_4241113_325457"
                                        id="id-bg-I8747577_4241113_325457"
                                    ></div>
                                </div>
                                <div
                                    className="footer-middle-icon_325458"
                                    id="id-I8747577_4241113_325458"
                                >
                                    <div
                                        className="footer-bg_4241113_325458"
                                        id="id-bg-I8747577_4241113_325458"
                                    ></div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-middle-dots" id="id-I8747577_424947">

                        </div>
                        <div className="footer-icons_4241095" id="id-I8747577_4241095">

                        </div>
                    </div>

                    <div className="footer-logo" id="id-I8747577_5002831">
                        <img src="../imgs/Diktatatorial.svg" alt="Diktatorial" />
                    </div>

                    <div className="footer-badge">
                        <a href="https://www.producthunt.com/posts/diktatorial-suite?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-diktatorial&#0045;suite" target="_blank" rel='noreferrer'><img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=446788&theme=light&period=daily" width="250" height="54" /></a>
                    </div>

                </div>

            </div>
        </footer>
    );
}

export default Footer;