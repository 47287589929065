import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function PrivacyPolicy() {

    useEffect(() => {
        document.title = "Privacy Policy | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="parent-div faq-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            <div className="regular-container">

                <div className="titling">
                    <div className="titling-0">Diktatorial Suite</div>
                    <div className="titling-1">Privacy Agreement</div>
                </div>

                <div className="regular-wrapper">

                    <h3>1. Information We Collect</h3>
                    <p>
                        DIKTATORIAL Inc. (&quot;DIKTATORIAL&quot;) collects the following
                        information about its users:
                    </p>

                    <p>
                        - Personal information, such as your name, email address, and
                        mailing address.
                    </p>

                    <p>
                        - Usage information, such as the pages you visit on the Service,
                        the features you use, and the time you spend using the
                        Service.
                    </p>

                    <p>
                        - Device information, such as the type of device you use to
                        access the Service and your IP address.
                    </p>

                    <p>
                        - Music/audio pieces uploaded to the Service by users.
                    </p>

                    <br />
                    <h3>2. How We Use Your Information</h3>
                    <p>
                        DIKTATORIAL uses the information it collects about its users to:
                    </p>

                    <p>
                        - Provide and improve the Service.
                    </p>

                    <p>
                        - Communicate with you about the Service.
                    </p>

                    <p>
                        - Send you marketing and promotional materials. (As long as
                        you want to receive)
                    </p>

                    <p>
                        - Conduct research.
                    </p>

                    <p>
                        - Make our services even better. (WE DO NOT train our AI on your audio pieces.)
                    </p>

                    <br />
                    <h3>3. Sharing Your Information</h3>
                    <p>
                        DIKTATORIAL will not share your music pieces with any third
                        parties without your consent. However, DIKTATORIAL may share
                        your personal information with third parties for the following
                        purposes:
                    </p>

                    <p>
                        - To provide you with the Service, such as to process payments
                        or to provide customer support.
                    </p>

                    <p>
                        - To comply with legal requirements, such as to respond to a
                        subpoena or court order.
                    </p>

                    <p>
                        - To protect the rights and safety of DIKTATORIAL and its users.
                    </p>

                    <br />
                    <h3>4. Data Retention</h3>
                    <p>
                        DIKTATORIAL will retain your personal information and music
                        pieces for as long as necessary to provide you with the Service and
                        to comply with its legal obligations.
                    </p>

                    <br />
                    <h3>5. Your Choices</h3>
                    <p>
                        You have the following choices regarding your personal
                        information:
                    </p>

                    <p>
                        - You can request access to your personal information.
                    </p>

                    <p>
                        - You can request that your personal information be corrected
                        or deleted.
                    </p>

                    <p>
                        - You can opt out of receiving marketing and promotional
                        materials from DIKTATORIAL.
                    </p>

                    <p>
                        To exercise these choices, please contact contact@diktatorial.com
                    </p>

                    <br />
                    <h3>6. Changes to this Privacy Agreement</h3>
                    <p>
                        DIKTATORIAL may revise this Privacy Agreement from time to time.
                        If DIKTATORIAL makes any material changes to this Privacy
                        Agreement, DIKTATORIAL will notify you by email or by posting a
                        notice on the Service.
                    </p>

                    <br />
                    <h3>7. Contact Us</h3>
                    <p>
                        If you have any questions about this Privacy Agreement, please
                        contact contact@diktatorial.com
                    </p>

                    <br />
                    <h3>Additional Information</h3>
                    <p>
                        - DIKTATORIAL provides cloud storage to users as long as they
                        stay as subscribers.
                    </p>

                    <p>
                        - DIKTATORIAL serves monthly and annual subscription options
                        and the right to change them in the future without notice.
                    </p>

                    <p>
                        - Producers and studio owners can upload their customers&apos;
                        tracks to DIKTATORIAL, as long as they have the rights to do
                        so.
                    </p>

                    <p>
                        - We don’t own any ownership on your music pieces. Masters are
                        yours, and you can monetize it however you want.
                    </p>

                </div>

            </div>

        </div>
    );
}

export default PrivacyPolicy;
