import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function NotFoundPage() {

    useEffect(() => {
        document.title = "Not Found | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className="parent-div faq-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            <div className="regular-container">

                <div className="titling">
                </div>

                <div className="regular-wrapper">

                <div className="error-page">
                <div className="error-page-image">
                    <img src="/imgs/404.svg" alt="404" />
                </div>
                <div className="error-page-text">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </div>
            </div>

                </div>

            </div>

        </div>
    );
}

export default NotFoundPage;
