import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urls } from '../config';
import { useQuery, useMutation } from '@tanstack/react-query'
import { apiInstance } from '../utils';
import { useUser } from '../hooks/useUser';

function PricingPage() {

    useEffect(() => {
        document.title = "Pricing and Suite Membership | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);



    // get products
    const { isLoading: loadingProducts, error: errorProducts, data: productList } = useQuery({
        queryKey: ['products'],
        queryFn: () => apiInstance.get('/api/products').then(res => res.data?.products),
        //initialData: []
    })



    // products
    const [lifetimeMembership, setLifetimeMembership] = useState(null);
    const [yearlyMembership, setYearlyMembership] = useState(null);
    const [monthlyMembership, setMonthlyMembership] = useState(null);
    const [singleMastering, setSingleMastering] = useState(null);
    const [extraMasteringLifetime, setExtraMasteringLifetime] = useState(null);
    const [extraMasteringYearly, setExtraMasteringYearly] = useState(null);
    const [extraMasteringMonthly, setExtraMasteringMonthly] = useState(null);
    const [extraMasteringEarlyBird, setExtraMasteringEarlyBird] = useState(null);
    useEffect(() => {
        if (productList && productList.length > 0) {
            productList.forEach(product => {
                switch (product.name) {
                    case "Pro Membership Lifetime":
                        setLifetimeMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Annual":
                        setYearlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Pro Membership Monthly":
                        setMonthlyMembership({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Single Mastering":
                        setSingleMastering({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Lifetime":
                        setExtraMasteringLifetime({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Annual":
                        setExtraMasteringYearly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Monthly":
                        setExtraMasteringMonthly({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    case "Extra Masters Early Bird":
                        setExtraMasteringEarlyBird({
                            id: product.id,
                            name: product.name,
                            price: product.price,
                            orj_price: product.orj_price
                        });
                        break;
                    default:
                }
            });
        }
    }, [productList]);


    // format price
    const formatPrice = price => {
        const number = parseFloat(price);
        const rounded = number.toFixed(2);
        return Number(rounded);
    };
    // format procent
    const formatProcent = (price, orjPrice) => {
        const number = parseFloat(price);
        const orjNumber = parseFloat(orjPrice);
        const procent = (100 - (number / orjNumber) * 100).toFixed(0);
        const roundedProcent = Math.round(procent / 5) * 5;
        return roundedProcent;
    };



    // toggle plans
    const [paymentPlan, setPaymentPlan] = useState("annual");
    const handleSubscriptionToggle = (plan) => {
        setPaymentPlan(plan);
    };
    //const [isAnnual, setIsAnnual] = useState(true);
    const [isRegular, setIsRegular] = useState(true);
    /* const togglePlan = () => {
        setIsAnnual(!isAnnual);
    }; */
    const toggleRegular = () => {
        setIsRegular(!isRegular);
    }



    // redirect to gumroad
    const RedirectToGumroad = () => {
        window.open("https://berkancesur.gumroad.com/l/songsanctuary", "_blank");
    }



    // get userinfo
    const userInfo = useUser();
    // get userdetails
    const { isLoading: loadingUserDetails, data: userDetails } = useQuery({
        queryKey: ['userDetails', userInfo?._d_i],
        queryFn: () =>
            apiInstance.get(`/api/musicians/${userInfo._d_i}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userInfo._d_a}`
                }
            }).then(res => res.data),
        enabled: !!userInfo
    })


    // early bird list with ids
    
    // check if user is active subscriber or early bird
    const [isEarlyBird, setIsEarlyBird] = useState(false);
    useEffect(() => {
        const earlyBirdList = [296, 178, 157, 174, 213, 172, 170, 184, 169, 152, 317, 246, 214, 254, 316, 222, 239, 252, 244, 231, 297, 299, 293, 274, 265, 310, 335, 150, 355, 339, 373, 381, 383, 151, 173];
        if (userDetails && userDetails?.is_active_subscriber) {
            setIsRegular(false);
            if (userDetails?.id && earlyBirdList.includes(userDetails?.id)) {
                setIsEarlyBird(true);
            } 
        }
    }, [userDetails]);


    // account type
    const [accountType, setAccountType] = useState("Free");
    useEffect(() => {
        if (userDetails?.status === "Pro Lifetime") {
            setAccountType('Pro Lifetime');
            //setPaymentPlan("lifetime");
        } else if (userDetails?.status === "Pro Annual") {
            setAccountType('Pro Annual');
            //setPaymentPlan("lifetime");
        } else if (userDetails?.status === "Pro Monthly") {
            setAccountType('Pro Monthly');
        }
    }, [userDetails]);



    // create checkout session
    const createCheckoutSession = useMutation({
        mutationFn: async (checkoutData) => {
            const res = await apiInstance.post('/api/create-checkout-session',
                checkoutData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userInfo._d_a}`,
                    },
                }
            );
            return res.data;
        },
        onSuccess: (checkoutData) => {
            checkoutData && checkoutData.payment_url && window
                .open(checkoutData.payment_url, '_blank')
                .focus();
        },
    })
    // create checkout session function
    const createCheckoutSessionFunction = (p_id, quantity) => async (e) => {
        e.preventDefault()
        window.scrollTo(0, 0);
        if (userDetails && userDetails?.id) {
            createCheckoutSession.mutate({ product_id: p_id, user_id: userDetails.id, quantity: quantity })
        }
    }



    // increment and decrement
    const [quantity, setQuantity] = useState(1);
    const handleIncrement = () => {
        if (quantity >= 99) {
            return;
        }
        const newQuantity = quantity + 1;
        setQuantity(newQuantity);
    };
    const handleDecrement = () => {
        if (quantity > 1) {
            const newQuantity = quantity - 1;
            setQuantity(newQuantity);
        }
    };



    return (
        <div className="parent-div about-parent-div pricing-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            {
                loadingProducts || loadingUserDetails || createCheckoutSession.isPending ?
                    <div className='absoulteLoading'><img src="../imgs/absoulteLoading.svg" alt="Loading" /></div>
                    : errorProducts ?
                        <div className='absoulteLoading'>
                            <div className="loading-with-message">
                                <img src="/imgs/404.svg" alt="error" />
                                <span>Error loading data from server. Please try again later.</span>
                            </div>
                        </div>
                        :
                        productList && productList.length > 0 ?
                            <div className="pricing-container">

                                {
                                    (accountType !== "Pro Lifetime" && accountType !== "Pro Annual") &&
                                    <>
                                        <div className="titling">
                                            <div className="titling-0">Level up your sound</div>
                                            <div className="titling-1">Get early bird discount!</div>
                                        </div>

                                        <div className="pricing-card">
                                            <div className="card-header">
                                                <div className="title">Suite Membership</div>
                                                {
                                                    userDetails?.is_active_subscriber && userDetails?.status === "Pro Annual" ?
                                                        <div className="toggle-switch toggle-one">
                                                            <button className="first">
                                                                <span onClick={() => handleSubscriptionToggle("lifetime")}>Lifetime</span>
                                                                <span className="active"></span>
                                                            </button>
                                                        </div>
                                                        : userDetails?.is_active_subscriber && userDetails?.status === "Pro Monthly" ?
                                                            <div className="toggle-switch toggle-one">
                                                                {/* <button className={paymentPlan === "lifetime" ? 'first' : paymentPlan === "annual" ? 'second' : paymentPlan === "monthly" ? 'third' : null}> */}
                                                                <button className="first">
                                                                    {/* <span onClick={() => handleSubscriptionToggle("lifetime")}>Lifetime</span> */}
                                                                    <span onClick={() => handleSubscriptionToggle("annual")}>Annual</span>
                                                                    <span className="active"></span>
                                                                </button>
                                                            </div>
                                                            :
                                                                <div className="toggle-switch toggle-thirdly-ex">
                                                                    {/* <button className={paymentPlan === "lifetime" ? 'first' : paymentPlan === "annual" ? 'second' : paymentPlan === "monthly" ? 'third' : null}> */}
                                                                    <button className={paymentPlan === "lifetime" ? 'first' : paymentPlan === "annual" ? 'first' : paymentPlan === "monthly" ? 'second' : null}>
                                                                        {/* <span onClick={() => handleSubscriptionToggle("lifetime")}>Lifetime</span> */}
                                                                        <span onClick={() => handleSubscriptionToggle("annual")}>Annual</span>
                                                                        <span onClick={() => handleSubscriptionToggle("monthly")}>Monthly</span>
                                                                        <span className="active"></span>
                                                                    </button>
                                                                </div>
                                                }
                                            </div>

                                            {
                                                paymentPlan === "lifetime" ?
                                                    <>
                                                        <div className="old-price-section">
                                                            {
                                                                lifetimeMembership?.orj_price !== lifetimeMembership?.price &&
                                                                <span className='old-price-procent'>{formatProcent(lifetimeMembership?.price, lifetimeMembership?.orj_price)}% OFF</span>
                                                            }
                                                            <span className='old-price-procent'>PAY ONCE</span>
                                                            <span className='old-price-procent'>BEST DEAL</span>
                                                        </div>
                                                        <div className="price-section">
                                                            <span className="price">${formatPrice(lifetimeMembership?.price)}</span>
                                                            {
                                                                lifetimeMembership?.orj_price !== lifetimeMembership?.price ?
                                                                    <span className="term old-term"> ${formatPrice(lifetimeMembership?.orj_price)}</span>
                                                                    : <span className="term"> / one time</span>
                                                            }
                                                            <div className="price-gift-badge" onClick={RedirectToGumroad}>
                                                                <div className='pgb-text'>
                                                                    <div>Go annual premium to</div>
                                                                    <div>get $30 Notion template <span className='pgbt-color'>as a bonus!</span></div>
                                                                </div>
                                                                <div className='pgb-icon'>
                                                                    <img src="/imgs/notion-gift.svg" alt="Notion" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className="description">
                                                            Limited time - launch offer! Unlocks all effects, text prompting, and maxed out sound quality, plus initial 25 credits and 10 free credits every year. (Forever) Our best deal.
                                                        </p>
                                                    </>
                                                    : paymentPlan === "annual" ?
                                                        <>
                                                            <div className="old-price-section">
                                                                <span className='old-price'>${formatPrice(yearlyMembership?.orj_price / 12)}</span>
                                                                {
                                                                    yearlyMembership?.orj_price !== yearlyMembership?.price &&
                                                                    <span className='old-price-procent'>{formatProcent(yearlyMembership?.price, yearlyMembership?.orj_price)}% OFF</span>
                                                                }
                                                                {/* <span className='old-price-procent'>BEST DEAL</span> */}
                                                                {/* <span className='old-price-procent opp_alternate'>SUBSCRIPTION</span> */}
                                                            </div>
                                                            <div className="price-section">
                                                                <span className="price">${formatPrice(yearlyMembership?.price / 12)}</span>
                                                                <span className="term">/ per month</span>
                                                                <div className="price-gift-badge" onClick={RedirectToGumroad}>
                                                                    <div className='pgb-text'>
                                                                        <div>Go annual premium to</div>
                                                                        <div>get $30 Notion template <span className='pgbt-color'>as a bonus!</span></div>
                                                                    </div>
                                                                    <div className='pgb-icon'>
                                                                        <img src="/imgs/notion-gift.svg" alt="Notion" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="description">
                                                                Ideal for albums and EP. Unlocks all effects, text prompting, maxed out sound quality, plus 10 credits. Our best deal.
                                                            </p>
                                                        </>
                                                        : paymentPlan === "monthly" ?
                                                            <>
                                                                <div className="old-price-section">
                                                                    <span className='old-price'>${formatPrice(monthlyMembership?.orj_price)}</span>
                                                                    {
                                                                        monthlyMembership?.orj_price !== monthlyMembership?.price &&
                                                                        <span className='old-price-procent'>{formatProcent(monthlyMembership?.price, monthlyMembership?.orj_price)}% OFF</span>
                                                                    }
                                                                    {/* <span className='old-price-procent opp_alternate'>SUBSCRIPTION</span> */}
                                                                </div>
                                                                <div className="price-section">
                                                                    <span className="price">${formatPrice(monthlyMembership?.price)}</span>
                                                                    <span className="term">/ per month</span>
                                                                </div>
                                                                <p className="description">
                                                                    Monthly free credits and cheaper extra masterings. Prompt to Mastering included.
                                                                </p>
                                                            </>
                                                            : null
                                            }

                                            <h3 className="features-title">What’s included</h3>
                                            <div className="features-list">
                                                <ul className="left-column">
                                                    {
                                                        paymentPlan === "lifetime" ?
                                                            <>
                                                                <li className="bold"><span>25</span> Instant Mastering Credits <span>Included</span></li>
                                                                <li>Free<span> 10</span> Instant Mastering Credits (Annually - <span>Forever!</span>)</li>
                                                                <li>Extra Instant Masterings <span>%50 Cheaper</span></li>
                                                            </>
                                                            : paymentPlan === "annual" ?
                                                                <>
                                                                    <li className="bold"><span>10</span> Instant Mastering Credits <span>Included</span></li>
                                                                    <li><span>10</span> Instant Mastering Credits (Annually)</li>
                                                                    <li><span>Cheaper</span> Extra Instant Mastering Credits</li>
                                                                </>
                                                                : paymentPlan === "monthly" ?
                                                                    <>
                                                                        <li className="bold"><span>1</span> Instant Mastering Credit <span>Included</span></li>
                                                                        <li><span>1</span> Instant Mastering Credit (Monthly)</li>
                                                                        <li><span>Cheaper</span> Extra Instant Mastering Credits</li>
                                                                    </>
                                                                    : null
                                                    }
                                                    <li>Prompt to Mastering</li>
                                                    <li>10 Revisions Per Track</li>
                                                </ul>
                                                <ul className="right-column">
                                                    <li>HD-WAV, Up to 32 bit, 48000 Hz</li>
                                                    <li>AI Assisted Loudness Optimization and Alternative Algorithms</li>
                                                    <li>Analog Warmth & Saturation</li>
                                                    <li>Compression, Stereo Width, De-essing, Mono Compatibility</li>
                                                    <li>Cloud Storage</li>
                                                </ul>
                                            </div>
                                            {
                                                userDetails && paymentPlan === "lifetime" ?
                                                    <div onClick={createCheckoutSessionFunction(lifetimeMembership?.id, "1")}><button className="subscribe-button">Buy once, use FOREVER</button></div>
                                                    : userDetails && paymentPlan === "annual" ?
                                                        <div onClick={createCheckoutSessionFunction(yearlyMembership?.id, "1")}><button className="subscribe-button">Buy Subscription</button></div>
                                                        : userDetails && paymentPlan === "monthly" ?
                                                            <div onClick={createCheckoutSessionFunction(monthlyMembership?.id, "1")}><button className="subscribe-button">Buy Subscription</button></div>
                                                            :
                                                            <a href={urls.subscribe} target="_blank" rel="noreferrer"><button className="subscribe-button">Subscribe Now</button></a>
                                            }
                                        </div>
                                    </>
                                }

                                <div className="titling">
                                    <div className="titling-3">Instant Mastering</div>
                                    <div className="titling-4">Non-subscription credits. Ideal for singles.</div>
                                </div>

                                <div className="pricing-card">
                                    <div className="card-header">
                                        <div className="title">Instant Mastering</div>
                                        {
                                            !userDetails?.is_active_subscriber ?
                                                <div className="toggle-switch">
                                                    <button onClick={toggleRegular} className={isRegular ? 'first' : 'second'}>
                                                        <span>Regular</span>
                                                        <span>Suite</span>
                                                        <span className="active"></span>
                                                    </button>
                                                </div>
                                                :
                                                <div className="account-type">
                                                    Your account type:
                                                    <span>{accountType} {isEarlyBird ? "(Early Bird)" : null}</span>
                                                </div>
                                        }
                                    </div>
                                    {
                                        isRegular ?
                                            <>
                                                <div className="old-price-section">
                                                    <span className='old-price'>${formatPrice(singleMastering?.orj_price)}</span>
                                                    {
                                                        singleMastering?.orj_price !== singleMastering?.price &&
                                                        <span className='old-price-procent'>{formatProcent(singleMastering?.price, singleMastering?.orj_price)}% OFF</span>
                                                    }
                                                    {/* <span className='old-price-procent opp_alternate'>1 CREDITS</span> */}
                                                </div>
                                                <div className="price-section">
                                                    <span className="price">${formatPrice(singleMastering?.price)}</span>
                                                    <span className="term">/per track</span>
                                                </div>
                                                <p className="description">
                                                    Experience the transformative effect of our instant mastering service. Optimize your sound quality in a flash without any commitment.
                                                </p>
                                            </>
                                            :
                                            <>
                                                {
                                                    isEarlyBird ?
                                                        <>
                                                            <div className="old-price-section">
                                                                <span className='old-price'>${formatPrice(extraMasteringEarlyBird?.orj_price)}</span>
                                                                {
                                                                    extraMasteringEarlyBird?.orj_price !== extraMasteringEarlyBird?.price &&
                                                                    <span className='old-price-procent'>{formatProcent(extraMasteringEarlyBird?.price, extraMasteringEarlyBird?.orj_price)}% OFF</span>
                                                                }
                                                                {/* <span className='old-price-procent opp_alternate'>1 CREDITS</span> */}
                                                            </div>
                                                            <div className="price-section">
                                                                <span className="price">${formatPrice(extraMasteringEarlyBird?.price)}</span>
                                                                <span className="term">/per track</span>
                                                            </div>
                                                            <p className="description">
                                                            Suite members enjoy exclusive discounts on additional instant mastering credits. We recommend getting a Suite membership if you plan to master more than one track and seek the highest quality.
                                                            </p>
                                                        </>
                                                    : (!userDetails?.is_active_subscriber) || (userDetails?.is_active_subscriber && userDetails?.status === "Pro Lifetime") ?
                                                        <>
                                                            <div className="old-price-section">
                                                                <span className='old-price'>${formatPrice(extraMasteringLifetime?.orj_price)}</span>
                                                                {
                                                                    extraMasteringLifetime?.orj_price !== extraMasteringLifetime?.price &&
                                                                    <span className='old-price-procent'>{formatProcent(extraMasteringLifetime?.price, extraMasteringLifetime?.orj_price)}% OFF</span>
                                                                }
                                                                {/* <span className='old-price-procent opp_alternate'>1 CREDITS</span> */}
                                                            </div>
                                                            <div className="price-section">
                                                                <span className="price">${formatPrice(extraMasteringLifetime?.price)}</span>
                                                                <span className="term">/per track</span>
                                                            </div>
                                                            <p className="description">
                                                            Suite members enjoy exclusive discounts on additional instant mastering credits. We recommend getting a Suite membership if you plan to master more than one track or seek the highest quality.
                                                            </p>
                                                        </>
                                                        : userDetails?.is_active_subscriber && userDetails?.status === "Pro Annual" ?
                                                            <>
                                                                <div className="old-price-section">
                                                                    <span className='old-price'>${formatPrice(extraMasteringYearly?.orj_price)}</span>
                                                                    {
                                                                        extraMasteringYearly?.orj_price !== extraMasteringYearly?.price &&
                                                                        <span className='old-price-procent'>{formatProcent(extraMasteringYearly?.price, extraMasteringYearly?.orj_price)}% OFF</span>
                                                                    }
                                                                    {/* <span className='old-price-procent opp_alternate'>1 CREDITS</span> */}
                                                                </div>
                                                                <div className="price-section">
                                                                    <span className="price">${formatPrice(extraMasteringYearly?.price)}</span>
                                                                    <span className="term">/per track</span>
                                                                </div>
                                                                <p className="description">
                                                                Suite members enjoy exclusive discounts on additional instant mastering credits. We recommend getting a Suite membership if you plan to master more than one track or seek the highest quality.
                                                                </p>
                                                            </>
                                                            : userDetails?.is_active_subscriber && userDetails?.status === "Pro Monthly" ?
                                                                <>
                                                                    <div className="old-price-section">
                                                                        <span className='old-price'>${formatPrice(extraMasteringMonthly?.orj_price)}</span>
                                                                        {
                                                                            extraMasteringMonthly?.orj_price !== extraMasteringMonthly?.price &&
                                                                            <span className='old-price-procent'>{formatProcent(extraMasteringMonthly?.price, extraMasteringMonthly?.orj_price)}% OFF</span>
                                                                        }
                                                                        {/* <span className='old-price-procent opp_alternate'>1 CREDITS</span> */}
                                                                    </div>
                                                                    <div className="price-section">
                                                                        <span className="price">${formatPrice(extraMasteringMonthly?.price)}</span>
                                                                        <span className="term">/per track</span>
                                                                    </div>
                                                                    <p className="description">
                                                                    Suite members enjoy exclusive discounts on additional instant mastering credits. We recommend getting a Suite membership if you plan to master more than one track or seek the highest quality.
                                                                    </p>
                                                                </>
                                                                : null

                                                }
                                            </>
                                    }
                                    <h3 className="features-title">What’s included</h3>
                                    <div className="features-list">
                                        <ul className="left-column">
                                            <li>1 Instant Mastering Credit</li>
                                            <li>{isRegular ? '3' : '10'} Revisions Per Track</li>
                                            <li>Analog Warmth & Saturation</li>
                                            <li>HD-WAV, Up to 32 bit, 48000 Hz</li>
                                            <li>AI Assisted Loudness Optimization</li>
                                        </ul>
                                        {!isRegular ?
                                            <ul className="right-column">
                                                <li>Alternative Algorithms</li>
                                                <li>Compression, Stereo Width, De-essing</li>
                                                <li>Mono Compatibility Tools</li>
                                            </ul>
                                            : null

                                        }
                                    </div>
                                    {
                                        userDetails && ((isRegular && userDetails?.is_active_subscriber) || (!isRegular && !userDetails?.is_active_subscriber)) ?
                                            <div onClick={toggleRegular}><button className="subscribe-button deactive_sb">Buy {quantity} Instant Mastering Credit</button></div>
                                            : userDetails && ((isRegular && !userDetails?.is_active_subscriber) || (!isRegular && userDetails?.is_active_subscriber)) ?
                                                <>
                                                    <div className="subscribe-button-container">
                                                        <button className="decrement-button" onClick={handleDecrement}><img src="/imgs/minus.svg" alt="Minus" /></button>
                                                        {
                                                            isRegular ?
                                                                <button className="subscribe-button" onClick={createCheckoutSessionFunction(singleMastering?.id, quantity)}>Buy {quantity} Instant Mastering Credit</button>
                                                                : !isRegular && (!userDetails?.is_active_subscriber) || (userDetails?.is_active_subscriber && userDetails?.status === "Pro Lifetime") ?
                                                                    <button className="subscribe-button" onClick={createCheckoutSessionFunction(extraMasteringLifetime?.id, quantity)}>Buy {quantity} Instant Mastering Credit</button>
                                                                    : !isRegular && userDetails?.is_active_subscriber && userDetails?.status === "Pro Annual" ?
                                                                        <button className="subscribe-button" onClick={createCheckoutSessionFunction(extraMasteringYearly?.id, quantity)}>Buy {quantity} Instant Mastering Credit</button>
                                                                        : !isRegular && userDetails?.is_active_subscriber && userDetails?.status === "Pro Monthly" ?
                                                                            <button className="subscribe-button" onClick={createCheckoutSessionFunction(extraMasteringMonthly?.id, quantity)}>Buy {quantity} Instant Mastering Credit</button>
                                                                            : null
                                                        }
                                                        <button className="increment-button" onClick={handleIncrement}><img src="/imgs/plus.svg" alt="Plus" /></button>
                                                    </div>
                                                </>
                                                :
                                                <a href={urls.subscribe} target="_blank" rel="noreferrer"><button className="subscribe-button">Get Suite For Cheaper Credits</button></a>
                                    }
                                    {
                                        isRegular && userDetails?.is_active_subscriber ?
                                            <div className="extra-info">
                                                <span>As a Suite member, you can get extra mastering credits for cheaper.</span>
                                            </div>
                                            : !isRegular && !userDetails?.is_active_subscriber ?
                                                <div className="extra-info">
                                                    <span>Get Suite to get extra mastering credits for cheaper.</span>
                                                </div>
                                                : null
                                    }
                                </div>

                            </div>
                            : null
            }

        </div>
    );
}

export default PricingPage;
