import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urls, user } from '../config';

function AboutPage() {

    useEffect(() => {
        document.title = "About | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <div className="parent-div about-parent-div">
                <div className="node-8749836" id="id-8749836">

                    <div className="main-bg-right-1" id="id-8747549"></div>

                    <div className="node-9787052" id="id-9787052">

                        <div className="node-8749837" id="id-8749837"></div>

                        <div className="node-9787053" id="id-9787053">

                            <div className="node-8749916" id="id-8749916">
                                <span className="node-8749916-0">
                                    {
                                        "Diktatorial Suite is your very private VIRTUAL audio mastering engineer, you can even talk to."
                                    }
                                </span>
                            </div>

                            <div className="node-8749911" id="id-8749911">
                                <span className="node-8749911-0">{"sound"}</span>
                            </div>

                            <div className="node-8749838" id="id-8749838">
                                <span className="node-8749838-0">{"your"}</span>
                            </div>

                            <div className="node-8749915" id="id-8749915">
                                <span className="node-8749915-0">{"maximize"}</span>
                            </div>

                            <div className="node-8749923 d-none" id="id-8749923">

                                <div className="node-8749924" id="id-8749924">
                                    <span className="node-8749924-0">{"+300K"}</span>
                                </div>

                                <div className="node-8749925" id="id-8749925">
                                    <span className="node-8749925-0">{"Tracks downloaded"}</span>
                                </div>
                            </div>

                            <div className="node-8749920 d-none" id="id-8749920">

                                <div className="node-8749921" id="id-8749921">
                                    <span className="node-8749921-0">{"+65K"}</span>
                                </div>

                                <div className="node-8749922" id="id-8749922">
                                    <span className="node-8749922-0">
                                        {"Artists and producers using our suite"}
                                    </span>
                                </div>
                            </div>

                            <a href={urls.subscribe} target="_blank" rel="noreferrer" className="anode-8749917">
                                <div className="node-8749917" id="id-8749917">

                                    <div className="node-8749918" id="id-8749918">
                                        <span className="node-8749918-0">{"2000+"}</span>
                                    </div>

                                    <div className="node-8749919" id="id-8749919">
                                        <span className="node-8749919-0">{"trusted by 2000+ masterings and counting!"}</span>
                                    </div>

                                </div>
                            </a>
                        </div>

                        <div className="node-8749912" id="id-8749912">
                            <img src="../imgs/Diktatatorial-icon.svg" alt="Diktatorial" />
                        </div>

                        <div className="node-8749913" id="id-8749913">
                            <img src="../imgs/about-bg.svg" alt="Diktatorial" />
                        </div>
                    </div>

                    <div className="node-9787051" id="id-9787051">

                        <div className="node-8749943" id="id-8749943"></div>
                        <div className="node-8749944" id="id-8749944"></div>

                        <div className="node-8749926" id="id-8749926">
                            <div className="node-8749926-0">
                                {"who dis?"}
                            </div>
                            <div className="node-8749926-1">{"Diktatorial Suite"}</div>
                        </div>

                        <div className="node-8749930" id="id-8749930">
                            <span className="node-8749930-0">
                                <div>
                                    {
                                        "Diktatorial Suite is an audio/music mastering tool developed by friends who are passionate about music and technology. We love to play with sound from heart at the company. "
                                    }
                                </div>
                                <br />
                                <div>
                                    {
                                        "We tried all other online music mastering tools when we started to our music career as well but the results were not sounding professional. In the end, we wanted to develop the best online mastering tool for musicians worldwide, combining deep learning with LLM technology."
                                    }
                                </div>
                                <br />
                                <div>
                                    {
                                        "We know the grind – the emotion and detail that go into every track. The Diktatorial Suite isn't just about mastering; it's about respect for the craft and the drive to unleash potential. With us, your music's details, energy, and soul remain intact, only presented in the most flattering sonic light."
                                    }
                                </div>
                                <br />
                                <div>
                                    {
                                        "That's just the beginning for Diktatorial. Our team is constantly pushing the boundaries of what is possible with music technology, and we have ambitious plans to expand the platform with new features and capabilities in the near future. We believe that music is a universal language that brings people together, and we are committed to providing the tools that empower artists to realize their creative vision."
                                    }
                                </div>
                            </span>
                        </div>

                    </div>

                    <div className="node-8749931" id="id-8749931">

                        <div className="node-8749927" id="id-8749927">
                            <span className="node-8749927-0">{"Core Team"}</span>
                        </div>

                        <div className="node-9787054" id="id-9787054">

                            <div className="node-9787047">
                                <div className="node-8749933">
                                    <img src="../imgs/can_ferman.png" alt="Diktatorial" />
                                </div>
                                <div className="node-8749935">
                                    <div className="node-8749935-1">{"Can Ferman"}</div>
                                    <div className="node-8749935-0">
                                        <div>{"Frontend / LLM Developer"}</div>
                                        <div>{"Co-Owner"}</div>
                                    </div>
                                    <div className='node-8749935-2'>
                                        <a href={user.can.github} target="_blank" rel="noreferrer"><img src="../imgs/github.svg" alt="github" /></a>
                                        <a href={user.can.linkedin} target="_blank" rel="noreferrer"><img src="../imgs/linkedin.svg" alt="linkedin" /></a>
                                        <a href={user.can.instagram} target="_blank" rel="noreferrer"><img src="../imgs/instagram.svg" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="node-8749939">
                                    <div className="nodeBg-8749939"></div>
                                </div>
                            </div>

                            <div className="node-9787047">
                                <div className="node-8749933">
                                    <img src="../imgs/selin.png" alt="Diktatorial" />
                                </div>
                                <div className="node-8749935">
                                    <div className="node-8749935-1">{"Selin"}</div>
                                    <div className="node-8749935-0">
                                        <div>{"Backend / DSP Developer"}</div>
                                        <div>{"Co-Owner"}</div>
                                    </div>
                                    <div className='node-8749935-2'>
                                        <a href={user.selin.github} target="_blank" rel="noreferrer"><img src="../imgs/github.svg" alt="github" /></a>
                                        <a href={user.selin.linkedin} target="_blank" rel="noreferrer"><img src="../imgs/linkedin.svg" alt="linkedin" /></a>
                                        <a href={user.selin.spotify} target="_blank" rel="noreferrer"><img src="../imgs/spotify.svg" alt="spotify" /></a>
                                        <a href={user.selin.instagram} target="_blank" rel="noreferrer"><img src="../imgs/instagram.svg" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="node-8749939">
                                    <div className="nodeBg-8749939"></div>
                                </div>
                            </div>

                            <div className="node-9787047">
                                <div className="node-8749933">
                                    <img src="../imgs/berkan.png" alt="Diktatorial" />
                                </div>
                                <div className="node-8749935">
                                    <div className="node-8749935-1">{"Berkan"}</div>
                                    <div className="node-8749935-0">
                                        <div>{"CEO / Product"}</div>
                                        <div>{"Co-Owner"}</div>
                                    </div>
                                    <div className='node-8749935-2'>
                                        <a href={user.berkan.github} target="_blank" rel="noreferrer"><img src="../imgs/github.svg" alt="github" /></a>
                                        <a href={user.berkan.linkedin} target="_blank" rel="noreferrer"><img src="../imgs/linkedin.svg" alt="linkedin" /></a>
                                        <a href={user.berkan.spotify} target="_blank" rel="noreferrer"><img src="../imgs/spotify.svg" alt="spotify" /></a>
                                        <a href={user.berkan.instagram} target="_blank" rel="noreferrer"><img src="../imgs/instagram.svg" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="node-8749939">
                                    <div className="nodeBg-8749939"></div>
                                </div>
                            </div>

                            <div className="node-9787047">
                                <div className="node-8749933">
                                    <img src="../imgs/tolga.png" alt="Diktatorial" />
                                </div>
                                <div className="node-8749935">
                                    <div className="node-8749935-1">{"Tolga"}</div>
                                    <div className="node-8749935-0">
                                        <div>{"Design Head"}</div>
                                        <div>{"Co-Owner"}</div>
                                    </div>
                                    <div className='node-8749935-2'>
                                        <a href={user.tolga.linkedin} target="_blank" rel="noreferrer"><img src="../imgs/linkedin.svg" alt="linkedin" /></a>
                                        <a href={user.tolga.spotify} target="_blank" rel="noreferrer"><img src="../imgs/spotify.svg" alt="spotify" /></a>
                                        <a href={user.tolga.instagram} target="_blank" rel="noreferrer"><img src="../imgs/instagram.svg" alt="instagram" /></a>
                                    </div>
                                </div>
                                <div className="node-8749939">
                                    <div className="nodeBg-8749939"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default AboutPage;
