import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { urls } from '../config';

function HomePage() {

    useEffect(() => {
        document.title = "Online AI Audio Mastering Tool with Texting | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    /* _______________________________________________ANIMATED_TEXT_______________________________________________ */
    // animated text
    const textRef = useRef(null);
    useEffect(() => {
        const text = textRef.current;
        const words = [
            "Breathing highs, wide stereo field, boost on mid frequencies",
            "Optimize for a club that has MONO speakers only",            
            "Remove harshness in the song",            
            "I want it to sound like Metallica's old days",
            "Jazz fusion style, with a clean and balanced mix",
            "Warm like modern hip hop hits, with punchy bass",
            "Classic blues tone, with a warm, gritty texture",
            "I'm aiming for the cinematic depth of a film score",
            "Make my track sound like a 90s grunge album.",
        ];
        setTyper(text, words);
        function setTyper(element, words) {
            const LETTER_TYPE_DELAY = 75;
            const LETTER_DELETE_DELAY = 25;
            const WORD_STAY_DELAY = 2000;
            const DIRECTION_FORWARDS = 0;
            const DIRECTION_BACKWARDS = 1;
            var direction = DIRECTION_FORWARDS;
            var wordIndex = 0;
            var letterIndex = 0;
            var wordTypeInterval;
            startTyping();
            function startTyping() {
                clearInterval(wordTypeInterval);
                let intervalDelay = direction === DIRECTION_FORWARDS ? LETTER_TYPE_DELAY : LETTER_DELETE_DELAY;
                wordTypeInterval = setInterval(typeLetter, intervalDelay);
            }
            function typeLetter() {
                const word = words[wordIndex];
                if (direction === DIRECTION_FORWARDS) {
                    letterIndex++;
                    if (letterIndex === word.length) {
                        setTimeout(() => {
                            direction = DIRECTION_BACKWARDS;
                            startTyping();
                        }, WORD_STAY_DELAY);
                    }
                } else if (direction === DIRECTION_BACKWARDS) {
                    letterIndex--;
                    if (letterIndex === 0) {
                        nextWord();
                    }
                }
                element.textContent = word.substring(0, letterIndex);
            }
            function nextWord() {
                direction = DIRECTION_FORWARDS;
                wordIndex = (wordIndex + 1) % words.length;
                startTyping();
            }
        }
    }, []);




    /* _______________________________________________DEFAULT_VALUES_______________________________________________ */
    // genre change
    const [genre, setGenre] = useState('Jazz');
    // option change
    const [option, setOption] = useState('Stereo With');




    /* _______________________________________________EXMAPLE_SECTION_______________________________________________ */
    // example music functions
    const [playing, setPlaying] = useState(false);
    const [loading, setLoading] = useState(true);
    const [masteringEnabled, setMasteringEnabled] = useState(true);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [tracks, setTracks] = useState(null);
    useEffect(() => {
        if (tracks) return;
        const timer = setTimeout(() => {
            setTracks({
                example1: new Audio('/medias/0-ilkzaman-off.mp3'),
                example2: new Audio('/medias/0-ilkzaman-on.mp3')
            });
        }, 1000);
        return () => clearTimeout(timer);
    }, [tracks]);
    useEffect(() => {
        if (!tracks) return;
        setCurrentTrack(tracks.example1);
        setLoading(false);
    }, [tracks]);
    useEffect(() => {
        if (!loading) {
            const newTrack = masteringEnabled ? tracks.example2 : tracks.example1;
            if (currentTrack !== newTrack) {
                const currentTime = currentTrack?.currentTime || 0;
                if (playing) currentTrack?.pause();
                newTrack.currentTime = currentTime;
                setCurrentTrack(newTrack);
                if (playing) newTrack.play();
            }
        }
    }, [masteringEnabled, playing, currentTrack, tracks, loading]);
    const togglePlay = () => {
        if (loading || !currentTrack) return;
        pauseAllTracksExcept(currentTrack);
        setPlaying(!playing);
        playing ? currentTrack.pause() : currentTrack.play();
    };
    useEffect(() => {
        if (!currentTrack) return;
        const handleSongEnd = () => setPlaying(false);
        currentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            currentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [currentTrack]);




    /* _______________________________________________ANALOG_SECTION_______________________________________________ */
    // analog music functions
    const [analogPlaying, setAnalogPlaying] = useState(false);
    const [analogLoading, setAnalogLoading] = useState(true);
    const [analogMasteringEnabled, setAnalogMasteringEnabled] = useState(true);
    const [analogCurrentTrack, setAnalogCurrentTrack] = useState(null);
    const [analogTracks, setAnalogTracks] = useState(null);
    useEffect(() => {
        if (analogTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setAnalogTracks({
                    AnalogExample1: new Audio('/medias/1-analog-off.mp3'),
                    AnalogExample2: new Audio('/medias/1-analog-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('analog-track');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [analogTracks]);
    useEffect(() => {
        if (!analogTracks) return;
        setAnalogCurrentTrack(analogTracks.AnalogExample1);
        setAnalogLoading(false);
    }, [analogTracks]);
    useEffect(() => {
        if (!analogLoading) {
            const newTrack = analogMasteringEnabled ? analogTracks.AnalogExample2 : analogTracks.AnalogExample1;
            if (analogCurrentTrack !== newTrack) {
                const currentTime = analogCurrentTrack?.currentTime || 0;
                if (analogPlaying) analogCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setAnalogCurrentTrack(newTrack);
                if (analogPlaying) newTrack.play();
            }
        }
    }, [analogMasteringEnabled, analogPlaying, analogCurrentTrack, analogTracks, analogLoading]);
    const toggleAnalogPlay = () => {
        if (analogLoading || !analogCurrentTrack) return;
        pauseAllTracksExcept(analogCurrentTrack);
        setAnalogPlaying(!analogPlaying);
        analogPlaying ? analogCurrentTrack.pause() : analogCurrentTrack.play();
    };
    useEffect(() => {
        if (!analogCurrentTrack) return;
        const handleSongEnd = () => setAnalogPlaying(false);
        analogCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            analogCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [analogCurrentTrack]);




    /* _______________________________________________JAZZ_SECTION_______________________________________________ */
    // jazz example music functions
    const [jazzPlaying, setJazzPlaying] = useState(false);
    const [jazzLoading, setJazzLoading] = useState(true);
    const [jazzMasteringEnabled, setJazzMasteringEnabled] = useState(true);
    const [jazzCurrentTrack, setJazzCurrentTrack] = useState(null);
    const [jazzTracks, setJazzTracks] = useState(null);
    useEffect(() => {
        if (jazzTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setJazzTracks({
                    JazzExample1: new Audio('/medias/2-jazz-soul-off.mp3'),
                    JazzExample2: new Audio('/medias/2-jazz-soul-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('genre-tracks');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [jazzTracks]);
    useEffect(() => {
        if (!jazzTracks) return;
        setJazzCurrentTrack(jazzTracks.JazzExample1);
        setJazzLoading(false);
    }, [jazzTracks]);
    useEffect(() => {
        if (!jazzLoading) {
            const newTrack = jazzMasteringEnabled ? jazzTracks.JazzExample2 : jazzTracks.JazzExample1;
            if (jazzCurrentTrack !== newTrack) {
                const currentTime = jazzCurrentTrack?.currentTime || 0;
                if (jazzPlaying) jazzCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setJazzCurrentTrack(newTrack);
                if (jazzPlaying) newTrack.play();
            }
        }
    }, [jazzMasteringEnabled, jazzPlaying, jazzCurrentTrack, jazzTracks, jazzLoading]);
    const toggleJazzPlay = () => {
        if (jazzLoading || !jazzCurrentTrack) return;
        pauseAllTracksExcept(jazzCurrentTrack);
        setJazzPlaying(!jazzPlaying);
        jazzPlaying ? jazzCurrentTrack.pause() : jazzCurrentTrack.play();
    };
    useEffect(() => {
        if (!jazzCurrentTrack) return;
        const handleSongEnd = () => setJazzPlaying(false);
        jazzCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            jazzCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [jazzCurrentTrack]);

    /* _______________________________________________TECHNO_SECTION_______________________________________________ */
    // Techno music functions
    const [technoPlaying, setTechnoPlaying] = useState(false);
    const [technoLoading, setTechnoLoading] = useState(true);
    const [technoMasteringEnabled, setTechnoMasteringEnabled] = useState(true);
    const [technoCurrentTrack, setTechnoCurrentTrack] = useState(null);
    const [technoTracks, setTechnoTracks] = useState(null);
    useEffect(() => {
        if (technoTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setTechnoTracks({
                    TechnoExample1: new Audio('/medias/3-techno-off.mp3'),
                    TechnoExample2: new Audio('/medias/3-techno-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('genre-tracks');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [technoTracks]);
    useEffect(() => {
        if (!technoTracks) return;
        setTechnoCurrentTrack(technoTracks.TechnoExample1);
        setTechnoLoading(false);
    }, [technoTracks]);
    useEffect(() => {
        if (!technoLoading) {
            const newTrack = technoMasteringEnabled ? technoTracks.TechnoExample2 : technoTracks.TechnoExample1;
            if (technoCurrentTrack !== newTrack) {
                const currentTime = technoCurrentTrack?.currentTime || 0;
                if (technoPlaying) technoCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setTechnoCurrentTrack(newTrack);
                if (technoPlaying) newTrack.play();
            }
        }
    }, [technoMasteringEnabled, technoPlaying, technoCurrentTrack, technoTracks, technoLoading]);
    const toggleTechnoPlay = () => {
        if (technoLoading || !technoCurrentTrack) return;
        pauseAllTracksExcept(technoCurrentTrack);
        setTechnoPlaying(!technoPlaying);
        technoPlaying ? technoCurrentTrack.pause() : technoCurrentTrack.play();
    };
    useEffect(() => {
        if (!technoCurrentTrack) return;
        const handleSongEnd = () => setTechnoPlaying(false);
        technoCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            technoCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [technoCurrentTrack]);

    /* _______________________________________________ROCK_SECTION_______________________________________________ */
    // Rock music functions
    const [rockPlaying, setRockPlaying] = useState(false);
    const [rockLoading, setRockLoading] = useState(true);
    const [rockMasteringEnabled, setRockMasteringEnabled] = useState(true);
    const [rockCurrentTrack, setRockCurrentTrack] = useState(null);
    const [rockTracks, setRockTracks] = useState(null);
    useEffect(() => {
        if (rockTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setRockTracks({
                    RockExample1: new Audio('/medias/4-rock-off.mp3'),
                    RockExample2: new Audio('/medias/4-rock-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('genre-tracks');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [rockTracks]);
    useEffect(() => {
        if (!rockTracks) return;
        setRockCurrentTrack(rockTracks.RockExample1);
        setRockLoading(false);
    }, [rockTracks]);
    useEffect(() => {
        if (!rockLoading) {
            const newTrack = rockMasteringEnabled ? rockTracks.RockExample2 : rockTracks.RockExample1;
            if (rockCurrentTrack !== newTrack) {
                const currentTime = rockCurrentTrack?.currentTime || 0;
                if (rockPlaying) rockCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setRockCurrentTrack(newTrack);
                if (rockPlaying) newTrack.play();
            }
        }
    }, [rockMasteringEnabled, rockPlaying, rockCurrentTrack, rockTracks, rockLoading]);
    const toggleRockPlay = () => {
        if (rockLoading || !rockCurrentTrack) return;
        pauseAllTracksExcept(rockCurrentTrack);
        setRockPlaying(!rockPlaying);
        rockPlaying ? rockCurrentTrack.pause() : rockCurrentTrack.play();
    };
    useEffect(() => {
        if (!rockCurrentTrack) return;
        const handleSongEnd = () => setRockPlaying(false);
        rockCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            rockCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [rockCurrentTrack]);

    /* _______________________________________________MINIMAL_HOUSE_SECTION_______________________________________________ */
    // Minimal music functions
    const [ambientPlaying, setAmbientPlaying] = useState(false);
    const [ambientLoading, setAmbientLoading] = useState(true);
    const [ambientMasteringEnabled, setAmbientMasteringEnabled] = useState(true);
    const [ambientCurrentTrack, setAmbientCurrentTrack] = useState(null);
    const [ambientTracks, setAmbientTracks] = useState(null);
    useEffect(() => {
        if (ambientTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setAmbientTracks({
                    AmbientExample1: new Audio('/medias/5-minimal-off.mp3'),
                    AmbientExample2: new Audio('/medias/5-minimal-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('genre-tracks');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [ambientTracks]);
    useEffect(() => {
        if (!ambientTracks) return;
        setAmbientCurrentTrack(ambientTracks.AmbientExample1);
        setAmbientLoading(false);
    }, [ambientTracks]);
    useEffect(() => {
        if (!ambientLoading) {
            const newTrack = ambientMasteringEnabled ? ambientTracks.AmbientExample2 : ambientTracks.AmbientExample1;
            if (ambientCurrentTrack !== newTrack) {
                const currentTime = ambientCurrentTrack?.currentTime || 0;
                if (ambientPlaying) ambientCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setAmbientCurrentTrack(newTrack);
                if (ambientPlaying) newTrack.play();
            }
        }
    }, [ambientMasteringEnabled, ambientPlaying, ambientCurrentTrack, ambientTracks, ambientLoading]);
    const toggleAmbientPlay = () => {
        if (ambientLoading || !ambientCurrentTrack) return;
        pauseAllTracksExcept(ambientCurrentTrack);
        setAmbientPlaying(!ambientPlaying);
        ambientPlaying ? ambientCurrentTrack.pause() : ambientCurrentTrack.play();
    };
    useEffect(() => {
        if (!ambientCurrentTrack) return;
        const handleSongEnd = () => setAmbientPlaying(false);
        ambientCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            ambientCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [ambientCurrentTrack]);

    /* _______________________________________________CINEMATIC_SECTION_______________________________________________ */
    // Cinematic music functions
    const [metalPlaying, setMetalPlaying] = useState(false);
    const [metalLoading, setMetalLoading] = useState(true);
    const [metalMasteringEnabled, setMetalMasteringEnabled] = useState(true);
    const [metalCurrentTrack, setMetalCurrentTrack] = useState(null);
    const [metalTracks, setMetalTracks] = useState(null);
    useEffect(() => {
        if (metalTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setMetalTracks({
                    MetalExample1: new Audio('/medias/6-cinematic-off.mp3'),
                    MetalExample2: new Audio('/medias/6-cinematic-on.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('genre-tracks');
        if (element) observer.observe(element);
        return () => observer.disconnect();
    }, [metalTracks]);
    useEffect(() => {
        if (!metalTracks) return;
        setMetalCurrentTrack(metalTracks.MetalExample1);
        setMetalLoading(false);
    }, [metalTracks]);
    useEffect(() => {
        if (!metalLoading) {
            const newTrack = metalMasteringEnabled ? metalTracks.MetalExample2 : metalTracks.MetalExample1;
            if (metalCurrentTrack !== newTrack) {
                const currentTime = metalCurrentTrack?.currentTime || 0;
                if (metalPlaying) metalCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setMetalCurrentTrack(newTrack);
                if (metalPlaying) newTrack.play();
            }
        }
    }, [metalMasteringEnabled, metalPlaying, metalCurrentTrack, metalTracks, metalLoading]);
    const toggleMetalPlay = () => {
        if (metalLoading || !metalCurrentTrack) return;
        pauseAllTracksExcept(metalCurrentTrack);
        setMetalPlaying(!metalPlaying);
        metalPlaying ? metalCurrentTrack.pause() : metalCurrentTrack.play();
    };
    useEffect(() => {
        if (!metalCurrentTrack) return;
        const handleSongEnd = () => setMetalPlaying(false);
        metalCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            metalCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [metalCurrentTrack]);




    /* _______________________________________________GENRE_CHANGES_______________________________________________ */
    const setJazzGenre = () => {
        setGenre('Jazz');
        pauseAllTracksExcept(jazzCurrentTrack);
    };
    const setTechnoGenre = () => {
        setGenre('Techno');
        pauseAllTracksExcept(technoCurrentTrack);
    };
    const setRockGenre = () => {
        setGenre('Rock');
        pauseAllTracksExcept(rockCurrentTrack);
    };
    const setAmbientGenre = () => {
        setGenre('Ambient');
        pauseAllTracksExcept(ambientCurrentTrack);
    };
    const setMetalGenre = () => {
        setGenre('Metal');
        pauseAllTracksExcept(metalCurrentTrack);
    };




    /* _______________________________________________OPTIONED_SECTION_______________________________________________ */
    // Optioned music functions
    const [optionedPlaying, setOptionedPlaying] = useState(false);
    const [optionedLoading, setOptionedLoading] = useState(true);
    const [optionedMasteringEnabled, setOptionedMasteringEnabled] = useState(true);
    const [optionedCurrentTrack, setOptionedCurrentTrack] = useState(null);
    const [optionedTracks, setOptionedTracks] = useState(null);
    const [lastSelectedTrack, setLastSelectedTrack] = useState('optionedExample1');
    useEffect(() => {
        if (optionedTracks) return;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setOptionedTracks({
                    optionedExample0: new Audio('/medias/effectoff.mp3'),
                    optionedExample1: new Audio('/medias/effecton_stereowidth.mp3'),
                    optionedExample2: new Audio('/medias/effecton_analogwarmth.mp3'),
                    optionedExample3: new Audio('/medias/effecton_saturation.mp3')
                });
                observer.disconnect();
            }
        }, { threshold: 0.1 });
        const element = document.getElementById('optioned-track');
        if (element) observer.observe(element);
        return () => {
            observer.disconnect();
        };
    }, [optionedTracks]);
    useEffect(() => {
        if (!optionedTracks) return;
        setOptionedCurrentTrack(optionedTracks[lastSelectedTrack]);
        setOptionedLoading(false);
    }, [optionedTracks, lastSelectedTrack]);
    const changeTrack = (trackName) => {
        if (optionedLoading || !optionedTracks) return;
        setOptionedMasteringEnabled(true);
        setLastSelectedTrack(trackName);

        const newTrack = optionedTracks[trackName];
        if (optionedCurrentTrack !== newTrack) {
            const currentTime = optionedCurrentTrack?.currentTime || 0;
            if (optionedPlaying) optionedCurrentTrack?.pause();
            newTrack.currentTime = currentTime;
            setOptionedCurrentTrack(newTrack);
            if (optionedPlaying) newTrack.play();
        }
    };
    useEffect(() => {
        if (!optionedLoading && !optionedMasteringEnabled) {
            const newTrack = optionedTracks['optionedExample0'];
            if (optionedCurrentTrack !== newTrack) {
                const currentTime = optionedCurrentTrack?.currentTime || 0;
                if (optionedPlaying) optionedCurrentTrack?.pause();
                newTrack.currentTime = currentTime;
                setOptionedCurrentTrack(newTrack);
                if (optionedPlaying) newTrack.play();
            }
        }
    }, [optionedMasteringEnabled, optionedPlaying, optionedCurrentTrack, optionedTracks, optionedLoading]);
    const toggleOptionedPlay = () => {
        if (optionedLoading || !optionedCurrentTrack) return;
        pauseAllTracksExcept(optionedCurrentTrack);
        setOptionedPlaying(!optionedPlaying);
        optionedPlaying ? optionedCurrentTrack.pause() : optionedCurrentTrack.play();
    };
    useEffect(() => {
        if (!optionedCurrentTrack) return;
        const handleSongEnd = () => setOptionedPlaying(false);
        optionedCurrentTrack.addEventListener('ended', handleSongEnd);
        return () => {
            optionedCurrentTrack.removeEventListener('ended', handleSongEnd);
        };
    }, [optionedCurrentTrack]);
    const handleOptionedExample1Play = () => {
        changeTrack('optionedExample1');
        setOption("Stereo With");
    };
    const handleOptionedExample2Play = () => {
        changeTrack('optionedExample2');
        setOption("Analog Warmth");
    };
    const handleOptionedExample3Play = () => {
        changeTrack('optionedExample3');
        setOption("Saturation");
    };
    const toggleOptionedMasteringEnabled = () => {
        setOptionedMasteringEnabled(!optionedMasteringEnabled);
        if (!optionedMasteringEnabled) {
            changeTrack(lastSelectedTrack);
            if (lastSelectedTrack === 'optionedExample1') setOption("Stereo With");
            if (lastSelectedTrack === 'optionedExample2') setOption("Analog Warmth");
            if (lastSelectedTrack === 'optionedExample3') setOption("Saturation");
        } else {
            setOption("");
        }
    };




    /* _______________________________________________PAUSE_TRACKS_______________________________________________ */
    const pauseAllTracksExcept = (activeTrack) => {
        const allTracks = [
            { track: currentTrack, setPlaying: setPlaying },
            { track: analogCurrentTrack, setPlaying: setAnalogPlaying },
            { track: jazzCurrentTrack, setPlaying: setJazzPlaying },
            { track: technoCurrentTrack, setPlaying: setTechnoPlaying },
            { track: rockCurrentTrack, setPlaying: setRockPlaying },
            { track: ambientCurrentTrack, setPlaying: setAmbientPlaying },
            { track: metalCurrentTrack, setPlaying: setMetalPlaying },
            { track: optionedCurrentTrack, setPlaying: setOptionedPlaying },
        ];
        allTracks.forEach(({ track, setPlaying }) => {
            if (track && track !== activeTrack) {
                track.pause();
                setPlaying(false);
            }
        });
    };




    /* _______________________________________________HTML_______________________________________________ */
    return (
        <>
            <div className="parent-div">
                <div className="parent-container" id="id-8747403">

                    <div className="main-bg-right-1" id="id-8747549"></div>

                    <div className="main-bg-left-1" id="id-8747550"></div>

                    <div className="introduction-wrapper" id="id-8747578">

                        <div className="intro-heading" id="id-8747672">
                            <div className="intro-badge" id="id-8747672">
                                <a href="https://www.producthunt.com/posts/diktatorial-suite?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-diktatorial-suite" target="_blank" rel="noreferrer">
                                    <img src="../imgs/ph-badge.svg" alt="DIKTATORIAL Suite" />
                                </a>
                            </div>
                            <div className="intro-h-texts" id="id-8747673">
                                <h1 className="intro-h-texts-0">Audio Mastering with Text Prompts</h1>
                                <h2 className="intro-h-texts-1">Talk to your virtual sound engineer</h2>
                            </div>
                        </div>

                        <div className="intro-input-wrapper" id="id-8747667">
                            <div className="intro-input-bg" id="id-8747668"></div>
                            <div className="intro-input-action" id="id-8747670">
                                <a href={urls.register} target="_blank" rel="noreferrer">
                                    <div className="intro-input-action-btn" id="id-I8747670_87410392">
                                        <span className="intro-input-action-btn-txt">Try Free</span>
                                    </div>
                                </a>
                            </div>
                            <div className="intro-input-text-container" id="id-8747669">
                                <div className="intro-input-text" id="id-I8747669_8748529" ref={textRef}></div>
                            </div>
                        </div>

                        <div className="intro-play-continer" id="id-8747580">

                            <div className="intro-play-icon" id="id-8747581">
                                <div className="node-8747582" id="id-8747582">
                                    <div className="nodeBg-8747582" id="id-bg-8747582"></div>
                                </div>
                                <div className="node-8747583" id="id-8747583">
                                    <div className="nodeBg-8747583" id="id-bg-8747583"></div>
                                </div>
                                <div className="node-8747584" id="id-8747584">
                                    <div className="nodeBg-8747584" id="id-bg-8747584"></div>
                                </div>
                                <div className="node-8747585" id="id-8747585">
                                    <div className="node-8747586" id="id-8747586">
                                        <div className="nodeBg-8747586" id="id-bg-8747586"></div>
                                    </div>
                                    <div className="node-8747587" id="id-8747587">
                                        <div className="nodeBg-8747587" id="id-bg-8747587"></div>
                                    </div>
                                    <div className="node-8747588" id="id-8747588">
                                        <div className="nodeBg-8747588" id="id-bg-8747588"></div>
                                    </div>
                                </div>
                                <div className="node-8747589" id="id-8747589">
                                    <div className="node-8747590" id="id-8747590">
                                        <div className="nodeBg-8747590" id="id-bg-8747590"></div>
                                    </div>
                                </div>
                            </div>

                            <div className="intro-play-texts" id="id-8747591">
                                <div className="intro-play-text" id="id-8747592">
                                    <span>last_one_really.wav</span>
                                </div>
                            </div>

                            <div className="intro-play-wave-container" id="id-8747594">

                                <div className={`waveform-mockup ${masteringEnabled ? 'waveform-mockup-mastered' : ''}`}></div>

                                <div className="intro-play-wave-check-wrapper">
                                    <div className="intro-play-wave-check-wrapper-container">
                                        <div className="mastering-controls">
                                            <span>Mastering ON</span>
                                            <label className="switch">
                                                <input type="checkbox" checked={masteringEnabled} onChange={() => setMasteringEnabled(!masteringEnabled)} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="intro-play-ply-btn-wrapper">
                                    <div className={`intro-play-ply-btn-container play-music ${!loading && playing ? 'play-music-active' : ''}`} onClick={togglePlay}>
                                        {loading && <img src="../imgs/loading.svg" alt="Loading" />}
                                        {!loading && playing && <img src="../imgs/pause.svg" alt="Pause" />}
                                        {!loading && !playing && <img src="../imgs/play.svg" alt="Play" />}
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="ss-wrapper" id="id-8747552">
                        <div className="ss-top-line" id="id-8747477"></div>
                        <div className="ss-bottom-line" id="id-8747576"></div>
                        <div className="ss-bg" id="id-8747553"></div>
                        <div className="ss-heading" id="id-8747554">
                            <div className="ss-heading-texts" id="id-8747555">
                                <h3 className="ss-heading-texts-0">Crystal clear audio, hi-fi quality online mastering</h3>
                                <h2 className="ss-heading-texts-1">Ready in seconds!</h2>
                            </div>
                        </div>
                        <div className="ss-image" id="id-8747556">
                            <div className="ss-image-container" id="id-8747557">
                                <img className="ss-image-self" src='../imgs/ss-area-with-input.jpg' alt="Diktatorial Suite" />
                            </div>
                        </div>
                    </div>

                    <div className="how-it-works-wrapper" id="analog-track">

                        <div className="how-it-works-heading" id="id-8747551">
                            <h3 className="how-it-works-heading-0">Text Prompts & Audio Mastering</h3>
                            <h2 className="how-it-works-heading-1">How it works?</h2>
                        </div>

                        <div className="hiw-animation-container">

                            <div className="hiw-animation-left-1" id="id-8747879">
                                <div className="node-I8747879_87410278" id="id-I8747879_87410278">
                                    <span className="node-I8747879_87410278-0">Your Music (.wav, .mp3)</span>
                                </div>
                            </div>

                            <div className="hiw-animation-left-2" id="id-8747880">
                                <div className="node-I8747880_87410278" id="id-I8747880_87410278">
                                    <span className="node-I8747880_87410278-0"
                                    >Prompt: <br />“Warm, powerful & wide stereo”</span
                                    >
                                </div>
                            </div>

                            <div className="hiw-animation-left-3" id="id-8747881">
                                <div className="node-I8747881_87410278" id="id-I8747881_87410278">
                                    <span className="node-I8747881_87410278-0">+Effects & Filters</span>
                                </div>
                            </div>

                            <div className="hiw-animation-left-4" id="id-8747882">
                                <a href={urls.register} target="_blank" rel="noreferrer">
                                    <span className="hiw-animation-left-4-0">Try this</span>
                                </a>
                            </div>

                            <div className="hiw-animation-left-5" id="id-8747883">
                                <div className="nodeBg-8747883" id="id-bg-8747883"></div>
                            </div>

                            <div className="hiw-animation-left-6" id="id-8747561">
                                <div className="node-I8747561_87410325" id="id-I8747561_87410325">
                                    <div
                                        className="nodeBg-I8747561_87410325"
                                        id="id-bg-I8747561_87410325"
                                    ></div>
                                </div>
                                <div className="node-I8747561_87410326" id="id-I8747561_87410326"></div>
                            </div>

                            <div className="hiw-animation-left-7" id="id-8747559">
                                <div className="node-I8747559_87410285" id="id-I8747559_87410285">
                                    <div
                                        className="nodeBg-I8747559_87410285"
                                        id="id-bg-I8747559_87410285"
                                    ></div>
                                </div>
                                <div className="node-I8747559_87410286" id="id-I8747559_87410286">
                                    <div
                                        className="nodeBg-I8747559_87410286"
                                        id="id-bg-I8747559_87410286"
                                    ></div>
                                </div>
                            </div>

                            <div className="hiw-animation-left-8" id="id-8747562">
                                <div className="node-I8747562_87410341" id="id-I8747562_87410341">
                                    <div
                                        className="nodeBg-I8747562_87410341"
                                        id="id-bg-I8747562_87410341"
                                    ></div>
                                </div>
                                <div className="node-I8747562_87410342" id="id-I8747562_87410342"></div>
                            </div>

                            <div className="hiw-animation-middle" id="id-8747563">
                                <div className="node-I8747563_87410358" id="id-I8747563_87410358"></div>
                                <div className="node-I8747563_87410357" id="id-I8747563_87410357"></div>
                                <div className="node-I8747563_87410359" id="id-I8747563_87410359"></div>
                                <div className="node-I8747563_87410361" id="id-I8747563_87410361">
                                    <div className="node-I8747563_87410362" id="id-I8747563_87410362"></div>
                                    <div className="node-I8747563_87410363" id="id-I8747563_87410363"></div>
                                </div>
                                <div className="node-I8747563_87410364" id="id-I8747563_87410364">
                                    <div className="node-I8747563_87410365" id="id-I8747563_87410365"></div>
                                    <div className="node-I8747563_87410366" id="id-I8747563_87410366"></div>
                                </div>
                                <div className="node-I8747563_87410367" id="id-I8747563_87410367">
                                    <div className="node-I8747563_87410368" id="id-I8747563_87410368"></div>
                                    <div className="node-I8747563_87410369" id="id-I8747563_87410369"></div>
                                </div>
                                <div className="node-I8747563_87410370" id="id-I8747563_87410370">
                                    <div className="node-I8747563_87410371" id="id-I8747563_87410371"></div>
                                    <div className="node-I8747563_87410372" id="id-I8747563_87410372"></div>
                                </div>
                                <div className="node-I8747563_87410360" id="id-I8747563_87410360">
                                    <div
                                        className="node-I8747563_87410360_8748602"
                                        id="id-I8747563_87410360_8748602"
                                    >
                                        <div
                                            className="nodeBg-I8747563_87410360_8748602"
                                            id="id-bg-I8747563_87410360_8748602"
                                        ></div>
                                    </div>
                                    <div
                                        className="node-I8747563_87410360_8748596"
                                        id="id-I8747563_87410360_8748596"
                                    >
                                        <div
                                            className="node-I8747563_87410360_8748597"
                                            id="id-I8747563_87410360_8748597"
                                        >
                                            <div
                                                className="nodeBg-I8747563_87410360_8748597"
                                                id="id-bg-I8747563_87410360_8748597"
                                            ></div>
                                        </div>
                                        <div
                                            className="node-I8747563_87410360_8748598"
                                            id="id-I8747563_87410360_8748598"
                                        >
                                            <div
                                                className="nodeBg-I8747563_87410360_8748598"
                                                id="id-bg-I8747563_87410360_8748598"
                                            ></div>
                                        </div>
                                        <div
                                            className="node-I8747563_87410360_8748599"
                                            id="id-I8747563_87410360_8748599"
                                        >
                                            <div
                                                className="nodeBg-I8747563_87410360_8748599"
                                                id="id-bg-I8747563_87410360_8748599"
                                            ></div>
                                        </div>
                                        <div
                                            className="node-I8747563_87410360_8748600"
                                            id="id-I8747563_87410360_8748600"
                                        >
                                            <div
                                                className="nodeBg-I8747563_87410360_8748600"
                                                id="id-bg-I8747563_87410360_8748600"
                                            ></div>
                                        </div>
                                        <div
                                            className="node-I8747563_87410360_8748601"
                                            id="id-I8747563_87410360_8748601"
                                        >
                                            <div
                                                className="nodeBg-I8747563_87410360_8748601"
                                                id="id-bg-I8747563_87410360_8748601"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hiw-animation-right-1" id="id-8747565">
                                <div className="node-I8747565_87410305" id="id-I8747565_87410305">
                                    <div
                                        className="nodeBg-I8747565_87410305"
                                        id="id-bg-I8747565_87410305"
                                    ></div>
                                </div>
                                <div className="node-I8747565_87410306" id="id-I8747565_87410306">
                                    <div
                                        className="nodeBg-I8747565_87410306"
                                        id="id-bg-I8747565_87410306"
                                    ></div>
                                </div>
                            </div>

                            <div className="hiw-animation-right-2" id="id-8747560">
                                <div className="node-I8747560_87410295" id="id-I8747560_87410295">
                                    <div
                                        className="nodeBg-I8747560_87410295"
                                        id="id-bg-I8747560_87410295"
                                    ></div>
                                </div>
                                <div className="node-I8747560_87410296" id="id-I8747560_87410296">
                                    <div
                                        className="nodeBg-I8747560_87410296"
                                        id="id-bg-I8747560_87410296"
                                    ></div>
                                </div>
                            </div>

                            <div className="hiw-animation-right-3" id="id-8747564">
                                <div className="node-I8747564_87410315" id="id-I8747564_87410315">
                                    <div
                                        className="nodeBg-I8747564_87410315"
                                        id="id-bg-I8747564_87410315"
                                    ></div>
                                </div>
                                <div className="node-I8747564_87410316" id="id-I8747564_87410316">
                                    <div
                                        className="nodeBg-I8747564_87410316"
                                        id="id-bg-I8747564_87410316"
                                    ></div>
                                </div>
                            </div>

                            <div className="hiw-animation-right-4" id="id-8747566"></div>

                            <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-2">
                                <div className={`intro-play-ply-btn-container play-music ${!analogLoading && analogPlaying ? 'play-music-active' : ''}`} onClick={toggleAnalogPlay}>
                                    {analogLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                    {!analogLoading && analogPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                    {!analogLoading && !analogPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                </div>
                            </div>

                            <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-2">
                                <div className="intro-play-wave-check-wrapper-container">
                                    <div className="mastering-controls">
                                        <span>Mastering ON</span>
                                        <label className="switch">
                                            <input type="checkbox" checked={analogMasteringEnabled} onChange={() => setAnalogMasteringEnabled(!analogMasteringEnabled)} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div className="features-wrapper">

                        <div className="features-heading" id="id-8747777">
                            <span className="features-heading-0">For audio professionals, musicians, mastering engineers<br />or starter bedroom producers.</span>
                        </div>

                        <div className="features-cards">

                            <div className="features-left" id="id-8747779">
                                <div className="features-left-bg" id="id-I8747779_8748675"></div>
                                <div className="features-left-inners_8748681" id="id-I8747779_8748681">
                                    <span className="features-left-inners_8748681-0">Streaming Optimized</span>
                                </div>
                                <div className="features-left-inners_8748682" id="id-I8747779_8748682">
                                    <span className="features-left-inners_8748682-0"
                                    >Instant optimization for Spotify, Apple Music, CD, and more... </span
                                    >
                                </div>
                                <div className="features-left-inners_8748676" id="id-I8747779_8748676">
                                    <img src="../imgs/cloud-storage-icon.png" alt="animation" />
                                </div>
                            </div>

                            <div className="features-middle" id="id-8747877">
                                <div className="features-middle-bg" id="id-I8747877_8749174"></div>
                                <div className="features-middle_8749175" id="id-I8747877_8749175">
                                    <span className="features-middle_8749175-0"
                                    >Endless Audio<br />Flavors</span
                                    >
                                </div>
                                <div className="features-middle_8749176" id="id-I8747877_8749176">
                                    <span className="features-middle_8749176-0"
                                    >Describe the sound you are aiming for, or tweak some knobs. Sonic possibilities are only limited by your imagination!</span
                                    >
                                </div>
                                <div className="features-middle_8749177" id="id-I8747877_8749177">
                                    <img src="../imgs/endless-audio-icon.png" alt="animation" />
                                </div>
                            </div>

                            <div className="features-right" id="id-8747878">
                                <div className="features-right-bg" id="id-I8747878_8748694"></div>
                                <div className="features-right_8749165" id="id-I8747878_8749165">
                                    <span className="features-right_8749165-0">Safe & <br />Secure</span>
                                </div>
                                <div className="features-right_8749166" id="id-I8747878_8749166">
                                    <span className="features-right_8749166-0"
                                    >We DO NOT train our AI on your music and share it with third parties, unlike our competitors! </span
                                    >
                                </div>
                                <div className="features-right_8748695" id="id-I8747878_8748695">
                                    <img src="../imgs/genre-detection-icon.png" alt="animation" />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="specs-wrapper">

                        <div className="specs-heading" id="id-8747778">
                            <span className="specs-heading-0">Developed by musicians <br />just like you!</span>
                        </div>

                        <div className="specs-info-container-1" id="genre-tracks">

                            <div className="specs-info-1-left" id="id-8747677">
                                <div className="node-8747678" id="id-8747678">
                                    <div className="nodeBg-8747678" id="id-bg-8747678"></div>
                                </div>
                                <div className="node-8747681">
                                    <div className="node-8747682">
                                        <div className={`node-8747772 ${genre === "Jazz" ? "active-genre" : ""}`} onClick={() => setJazzGenre()}>
                                            <div className="node-I8747772_8748665"></div>
                                            <div className="node-I8747772_8748666">
                                                <span className="node-I8747772_8748666-0">Soul/Jazz</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747773 ${genre === "Techno" ? "active-genre" : ""}`} onClick={() => setTechnoGenre()}>
                                            <div className="node-I8747773_8748665"></div>
                                            <div className="node-I8747773_8748666">
                                                <span className="node-I8747773_8748666-0">Techno</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747774 ${genre === "Rock" ? "active-genre" : ""}`} onClick={() => setRockGenre()}>
                                            <div className="node-I8747774_8748665"></div>
                                            <div className="node-I8747774_8748666">
                                                <span className="node-I8747774_8748666-0">Rock</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747775 ${genre === "Ambient" ? "active-genre" : ""}`} onClick={() => setAmbientGenre()}>
                                            <div className="node-I8747775_8748665"></div>
                                            <div className="node-I8747775_8748666">
                                                <span className="node-I8747775_8748666-0">Minimal House</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747776 ${genre === "Metal" ? "active-genre" : ""}`} onClick={() => setMetalGenre()}>
                                            <div className="node-I8747776_8748665"></div>
                                            <div className="node-I8747776_8748666">
                                                <span className="node-I8747776_8748666-0">Cinematic</span>
                                            </div>
                                        </div>

                                        {genre === "Jazz" &&
                                            <div className="action-controllers">
                                                <div className="node-8747683">
                                                    <div className={`nodeBg-8747683 ${jazzMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                                </div>

                                                <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                    <div className="intro-play-wave-check-wrapper-container">
                                                        <div className="mastering-controls">
                                                            <span>Mastering ON</span>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={jazzMasteringEnabled} onChange={() => setJazzMasteringEnabled(!jazzMasteringEnabled)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                    <div className={`intro-play-ply-btn-container play-music ${!jazzLoading && jazzPlaying ? 'play-music-active' : ''}`} onClick={toggleJazzPlay}>
                                                        {jazzLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                        {!jazzLoading && jazzPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                        {!jazzLoading && !jazzPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {genre === "Techno" &&
                                            <div className="action-controllers">
                                                <div className="node-8747683">
                                                    <div className={`nodeBg-8747683 ${technoMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                                </div>

                                                <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                    <div className="intro-play-wave-check-wrapper-container">
                                                        <div className="mastering-controls">
                                                            <span>Mastering ON</span>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={technoMasteringEnabled} onChange={() => setTechnoMasteringEnabled(!technoMasteringEnabled)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                    <div className={`intro-play-ply-btn-container play-music ${!technoLoading && technoPlaying ? 'play-music-active' : ''}`} onClick={toggleTechnoPlay}>
                                                        {technoLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                        {!technoLoading && technoPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                        {!technoLoading && !technoPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {genre === "Rock" &&
                                            <div className="action-controllers">
                                                <div className="node-8747683">
                                                    <div className={`nodeBg-8747683 ${rockMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                                </div>

                                                <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                    <div className="intro-play-wave-check-wrapper-container">
                                                        <div className="mastering-controls">
                                                            <span>Mastering ON</span>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={rockMasteringEnabled} onChange={() => setRockMasteringEnabled(!rockMasteringEnabled)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                    <div className={`intro-play-ply-btn-container play-music ${!rockLoading && rockPlaying ? 'play-music-active' : ''}`} onClick={toggleRockPlay}>
                                                        {rockLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                        {!rockLoading && rockPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                        {!rockLoading && !rockPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {genre === "Ambient" &&
                                            <div className="action-controllers">
                                                <div className="node-8747683">
                                                    <div className={`nodeBg-8747683 ${ambientMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                                </div>

                                                <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                    <div className="intro-play-wave-check-wrapper-container">
                                                        <div className="mastering-controls">
                                                            <span>Mastering ON</span>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={ambientMasteringEnabled} onChange={() => setAmbientMasteringEnabled(!ambientMasteringEnabled)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                    <div className={`intro-play-ply-btn-container play-music ${!ambientLoading && ambientPlaying ? 'play-music-active' : ''}`} onClick={toggleAmbientPlay}>
                                                        {ambientLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                        {!ambientLoading && ambientPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                        {!ambientLoading && !ambientPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {genre === "Metal" &&
                                            <div className="action-controllers">
                                                <div className="node-8747683">
                                                    <div className={`nodeBg-8747683 ${metalMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                                </div>

                                                <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                    <div className="intro-play-wave-check-wrapper-container">
                                                        <div className="mastering-controls">
                                                            <span>Mastering ON</span>
                                                            <label className="switch">
                                                                <input type="checkbox" checked={metalMasteringEnabled} onChange={() => setMetalMasteringEnabled(!metalMasteringEnabled)} />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                    <div className={`intro-play-ply-btn-container play-music ${!metalLoading && metalPlaying ? 'play-music-active' : ''}`} onClick={toggleMetalPlay}>
                                                        {metalLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                        {!metalLoading && metalPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                        {!metalLoading && !metalPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="specs-info-1-right" id="id-8747674">
                                <div className="node-8747675" id="id-8747675">
                                    <span className="node-8747675-0"
                                    >Every Genre is<br />Different</span
                                    >
                                </div>
                                <div className="node-8747676" id="id-8747676">
                                    <span className="node-8747676-0"
                                    >Discover the perfect sound for every genre. Whether you`re into the intense beats of techno and metal or the cozy vibes of lo-fi, we`ve got you covered.<br /><br />Diktatorial Suite is developed by musicians just like you. We tried all other online music mastering tools for us as well but the results were not sounding professional. In the end, we wanted to develop the best online mastering tool for musicians worldwide. </span
                                    >
                                </div>
                            </div>
                        </div>

                        <div className="specs-info-container-2" id="optioned-track">

                            <div className="specs-info-2-left">

                                <div className="node-8747780" id="id-8747780">
                                    <span className="node-8747780-0"
                                    >Revisions, Limited By Your Imagination</span
                                    >
                                </div>

                                <div className="node-8747781" id="id-8747781">
                                    <span className="node-8747781-0"
                                    >Talk to your virtual mastering engineer with text prompts and adjust sound to your taste. Liked what you hear? Click finalize, and you will get your master ready in just a min! <br /><br /> We are aware that every music piece takes a great deal of effort and there are many beautiful things hidden in details. You will not
                                    lose any of those details with Diktatorial Suite, we promise.</span
                                    >
                                </div>

                            </div>

                            <div className="specs-info-2-right">

                                <div className="node-8747782">
                                    <div className="node-8747783">
                                        <div className={`node-8747873 ${option === "Stereo With" ? "active-option" : ""}`} onClick={() => handleOptionedExample1Play()}>
                                            <div className="node-I8747873_8748665"></div>
                                            <div className="node-I8747873_8748666">
                                                <span className="node-I8747873_8748666-0">Stereo Width</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747874 ${option === "Analog Warmth" ? "active-option" : ""}`} onClick={() => handleOptionedExample2Play()}>
                                            <div className="node-I8747874_8748665"></div>
                                            <div className="node-I8747874_8748666">
                                                <span className="node-I8747874_8748666-0">Analog Warmth</span>
                                            </div>
                                        </div>
                                        <div className={`node-8747875 ${option === "Saturation" ? "active-option" : ""}`} onClick={() => handleOptionedExample3Play()}>
                                            <div className="node-I8747875_8748665"></div>
                                            <div className="node-I8747875_8748666">
                                                <span className="node-I8747875_8748666-0">Saturation</span>
                                            </div>
                                        </div>

                                        <div className="action-controllers">
                                            <div className="node-8747683">
                                                <div className={`nodeBg-8747683 ${optionedMasteringEnabled ? 'nodeBg-8747683-mastered' : ''}`}></div>
                                            </div>

                                            <div className="intro-play-wave-check-wrapper intro-play-wave-check-wrapper-3">
                                                <div className="intro-play-wave-check-wrapper-container">
                                                    <div className="mastering-controls">
                                                        <span>Effect ON</span>
                                                        <label className="switch">
                                                            <input type="checkbox" checked={optionedMasteringEnabled} onChange={() => toggleOptionedMasteringEnabled()} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="intro-play-ply-btn-wrapper intro-play-ply-btn-wrapper-3">
                                                <div className={`intro-play-ply-btn-container play-music ${!optionedLoading && optionedPlaying ? 'play-music-active' : ''}`} onClick={toggleOptionedPlay}>
                                                    {optionedLoading && <img src="../imgs/loading.svg" alt="Loading" />}
                                                    {!optionedLoading && optionedPlaying && <img src="../imgs/pause.svg" alt="Pause" />}
                                                    {!optionedLoading && !optionedPlaying && <img src="../imgs/play.svg" alt="Play" />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="subscription-wrapper" id="id-8747876">
                        <div className="subscription-container" id="id-I8747876_87410939"></div>
                        <div className="subscription-heading" id="id-I8747876_87410940">
                            <span className="subscription-heading-0"
                            >Your sound, your prompt, your ideas<br /></span>
                            <span className="subscription-heading-1">DIKTATORIAL</span>
                        </div>
                        <div className="subscription-desc" id="id-I8747876_87410941">
                            <span className="subscription-desc-0"
                            >Instant Audio/Music Mastering Tool Online</span>
                        </div>
                        <a href={urls.register} target="_blank" rel="noreferrer">
                            <div className="subscription-btn" id="id-I8747876_87410942">
                                <div
                                    className="subscription-btn_87410950"
                                    id="id-I8747876_87410942_87410950"
                                >
                                    <div
                                        className="subscription-btn_87410951"
                                        id="id-I8747876_87410942_87410951"
                                    ></div>
                                    <div
                                        className="subscription-btn_87410952"
                                        id="id-I8747876_87410942_87410952"
                                    >
                                        <span className="subscription-btn_87410952-0">Click here to try it free</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </>
    );
}

export default HomePage;
