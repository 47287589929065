import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function FaqPage() {

    useEffect(() => {
        document.title = "Frequently Asked Questions | DIKTATORIAL Suite";
    }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const [activeCardIndex, setActiveCardIndex] = useState(0);

    const faqData = [
        {
            question: "What's Mastering anyway?",
            answer: [
                "''Mastering is the art of presenting your mix in the best light possible. It is not about processing, it may be about how NOT to process.'' - Bob Katz",
                "Mastering is the process of refining and polishing a piece of music to ensure it is ready for release. It is an art form that involves enhancing sonic materials, conducting a thorough evaluation of the mix, identifying any problems, and enhancing the overall sound to bring out the best in the material.",
                "When done correctly, your song will feel more alive, loud, and satisfying, giving it that professional sound that listeners expect. Additionally, a good master usually results in a great audio experience on any platform, be it on headphones, speakers, or any other audio source. You get the idea!"
            ]
        },
        {
            question: "How can I prepare my song to mastering, so I can get the most of Diktatorial Suite’s AI mastering?",
            answer: [
                "Here are some tips for preparing your mix for mastering:",
                "1. Get a good mix: Before mastering, it's important to have a solid mix. This means making sure that each instrument is balanced and EQ'ed correctly, and that there is no distortion or clipping. Give yourself enough time to mix and listen to your tracks on different playback systems.",
                "2. Make your headroom breathe: When exporting your mix, make sure to avoid clipping and distortion on your master bus and If possible, deliver your mix to us in the quietest form possible. (Loudness and clarity are our characteristic specialties, so you can count on us!)",
                "3. Avoid over-processing: Master bus processing may needed in some songs, we understand that. But it's important to avoid over-processing in the mix stage. Too much compression or limiting in mix, can result in lifeless and flat master.",
                "4. Use high-quality audio files: Make sure you're using high-quality audio files for the mix. Use 24-bit or 32-bit audio files at a sample rate of 44.1kHz or higher. Avoid using compressed audio formats like MP3s.",
            ]
        },
        {
            question: "How does Diktatorial Suite's AI mastering works?",
            answer: [
                "We are a team of established musicians and software engineers who have mixed, published, and released many songs ourselves, we understand the needs of musicians.",
                "When you submit your song to us, our complex algorithms begin to analyze the audio file, detecting its genre, BPM, LUFS, and any potential sonic needs. From there, we apply various audio processing techniques to enhance the sound quality of the track in accordance with the initial analysis. And that’s it! You get your perfectly crafted master in a minute.",
                "You can always compare your mastered track to the original mix by using our A/B feature and make revisions until you achieve the perfect master.",
                "For Suite members, we offer an extensive range of options to enhance your master, including a rich variety of saturation, compression, analog warmth, and width. These tools can help to add depth, warmth, and character to your final product, ensuring that it stands out from the crowd.",
            ]
        },
        {
            question: "How does revisions (saturation, width, analog warmth etc.) work?",
            answer: [
                "We simply provide the widest range of sonic preferences in AI mastering industry.",
                "You can increase the saturation to achieve a stronger psychoacoustic effect, or you may try our analog warmth preferences for a smoother and clearer jazz/ambient master. If you want a new master with your new preferences, simply request a new revision. Your new master preview will be available in mere seconds, allowing you to A/B test it against your existing master takes.",
                "These all can be done with text prompts as well. Hell yes.",
            ]
        },
        {
            question: "What is Prompt to Mastering?",
            answer: [
                "Prompt to Mastering is our state-of-the-art audio processing technology that combines Large Language Models with an experience akin to having a chat with your own personal mastering engineer. You just tell us what you want your music to sound like. Maybe you want it punchy like a hip-hop hit, or raw like an old-school rock track. Whatever your style, just type it in as a prompt. Our system takes your words, figures out exactly what you're looking for, and applies that magic to your music. It's all about making your track sound like you imagine, using just a simple sentence or two. So go ahead, describe your dream sound, and we'll do the rest to make it come true!",
            ]
        },  
        {
            question: "Which options/prompts are best?",
            answer: [
                "At Diktatorial Suite, we aim for your music to sound its best from the start. Most times, our first try meets your needs and works everywhere. But remember, there's no single best setting. Sometimes, a small change or a new prompt can make your track perfect. If you like what you hear, just hit 'finalize mastering' to apply that algorithm to the entire song. Feel free to try different settings and prompts until it sounds just right to you. Finding your sound is all about exploring!",
            ]
        },                
        {
            question: "How does subscription plans/credits work?",
            answer: [
                "Our subscription plans are designed to offer flexibility and value to our users. With the Suite Membership, you'll receive a set number of monthly or annual credits, which you can use towards instant mastering services. Each credit entitles you to one mastering session for a track.",
                "If you opt for the annual subscription, you will get 10 credits instantly. The monthly subscription provides you with 1 credit each month, perfect for regular releases. For those who prefer no commitment, the Instant Mastering option allows you to purchase credits as you go.",
                "Unused monthly credits roll over to the next month. Any tracks mastered using your credits will remain in your cloud storage for easy access at any time.",
            ]
        },
        {
            question: "How lifetime plan works?",
            answer: [
                "If you buy the lifetime plan, you'll get 25 instant mastering credits instantly and you will receive FREE 10 credits every year at the purchase date. All access, all effects and text prompts as well.",
            ]
        },        
        {
            question: "Does revisions cost credits?",
            answer: [
                "No. Revisions are a part of our mastering process, allowing you to perfect your track without worrying about additional costs. For Suite Members, each credited mastering session includes up to 10 revisions per track, ensuring you can fine-tune your master to meet your exact standards.",
                "For non-members using Instant Mastering, you're entitled to 3 revisions per track. This gives you the flexibility to adjust and compare different mastering options. Revisions do not use additional credits; they're included in the initial credit you spend when you first submit your track for mastering, as we want to ensure that you're completely satisfied with your final master.",
            ]
        },      
    ];

    const toggleCard = index => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
    };

    return (
        <div className="parent-div faq-parent-div">

            <div className="main-bg-right-1" id="id-8747549"></div>

            <div className="regular-container">

                <div className="titling">
                    <div className="titling-0">FAQ</div>
                    <div className="titling-1">Frequently Asked Questions</div>
                </div>

                <div className="faq-card-wrapper">
                    {faqData.map((item, index) => (
                        <div key={index} className="faq-card">
                            <div className="card-header" onClick={() => toggleCard(index)}>{item.question}</div>
                            {activeCardIndex === index && (
                                <div className="card-description">
                                    {item.answer.map((paragraph, pIndex) => (
                                        <p key={pIndex}>{paragraph}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

            </div>

        </div>
    );
}

export default FaqPage;
